import { useEffect, useState } from "react";
import "./style.scss";

export default function Sidebar({ show, onClose, children }) {
  const [showChild, setShowChild] = useState(false);
  const [intermediateShow, setIntermediateShow] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowChild(true);
      }, 100);
    } else {
      setShowChild(false);
    }
  }, [show]);

  useEffect(() => {
    if (show) setIntermediateShow(true);
    else if (!showChild) {
      setTimeout(() => {
        setIntermediateShow(false);
      }, 300);
    }
  }, [show, showChild]);

  if (intermediateShow) {
    return (
      <div onClick={() => onClose()} className="sidebar-wrapper">
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={`side_bar ${showChild ? 'show' : ''}`}
        >
          {children}
        </div>
      </div>
    );
  }

  return null;
};
