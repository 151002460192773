import { Dialog, Grid, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FormContainer from "../../Form/FormContainer";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import DatePicker from "../../Form/DatePicker";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  addReminder,
  updateReminder,
  removeReminder
} from "../../../Services/User";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

const AddReminderModal = ({ reminderData, open, onClose, onOk }) => {
  const account = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    if (reminderData) {
      const res = await updateReminder(account.token, {
        ...values,
        remindAt: dayjs(values.remindAt).toISOString(),
        id: reminderData.id
      });
      onOk({
        type: "update",
        data: res.data?.reminders
      });
    } else {
      const res = await addReminder(account.token, {
        ...values,
        remindAt: dayjs(values.remindAt).toISOString()
      });
      onOk({
        type: "add",
        data: res.data?.reminders
      });
    }
    setLoading(false);
  };

  const validationSchema = Yup.object().shape(
    {
      title: Yup.string().required("Please provide title."),
      remindAt: Yup.string().required("Please provide Date & Time."),
      repeat: Yup.string().required("Please provide Repeat."),
      toEmail: Yup.string()
        .trim()
        .email("Please provide email.")
        .when("toPhone", {
          is: (toPhone) => !toPhone || toPhone.length === 0,
          then: Yup.string().required("Email or Phone is required")
        }),
      toPhone: Yup.string().when("toEmail", {
        is: (toEmail) => !toEmail || toEmail.length === 0,
        then: Yup.string().required("Email or Phone is required")
      })
    },
    ["toEmail", "toPhone"]
  );

  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await removeReminder(account.token, {
        id: reminderData.id
      });
      onOk({
        type: "delete",
        data: res.data?.reminders
      });
    } catch (error) {}
    setLoading(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 4, minWidth: "500px" } }}
    >
      <FormContainer
        defaultValues={reminderData}
        validation={validationSchema}
        onSuccess={handleFormSubmit}
      >
        <Box p={3}>
          <Box position="absolute" top="20px" right="20px">
            <CloseIcon
              sx={{ fontSize: 32, cursor: "pointer" }}
              color="#000000"
              onClick={() => onClose()}
            />
          </Box>

          <Box mb={4}>
            <Typography
              variant="h3"
              align="left"
              color="primary.dark"
              fontWeight={600}
            >
              Reminder
            </Typography>
          </Box>
          <Box mb={3}>
            <Input
              fullWidth
              normalInput={true}
              name="title"
              variant="outlined"
              label="Remind me to..."
            />
          </Box>
          <Box mb={3}>
            <DatePicker
              sx={{ width: "100%" }}
              name="remindAt"
              label="Date & Time"
            />
          </Box>
          <Box mb={3}>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Repeat"
              variant="outlined"
              name="repeat"
              optionList={[
                { value: "off", label: "Off" },
                { value: "day", label: "Daily" },
                { value: "week", label: "Weekly" },
                { value: "month", label: "Monthly" }
              ]}
            />
          </Box>
          <Box mb={3}>
            <Input
              normalInput={true}
              fullWidth
              name="toEmail"
              variant="outlined"
              label="Send to Email"
            />
          </Box>
          <Box mb={3}>
            <Input
              normalInput={true}
              fullWidth
              name="toPhone"
              variant="outlined"
              label="Send to Phone"
            />
          </Box>

          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {reminderData ? (
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    variant="outlined"
                    color="error"
                    size="large"
                    onClick={handleDelete}
                  >
                    Delete
                  </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={8}>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Create
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </FormContainer>
    </Dialog>
  );
};

export default AddReminderModal;
