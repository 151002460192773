import { Dialog } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconMail from "../../../Assets/Images/icon_mail.svg";
import "./style.scss";

const WelcomeModal = ({ open, onClose }) => {
  return (
    <Dialog className="welcome" open={open} onClose={onClose}>
      <div className="welcome_modal">
        <div className="welcome_modal-wrapper">
          <p className="welcome_modal-wrapper-close" onClick={() => onClose()}>
            <CloseIcon sx={{ fontSize: 32 }} />
          </p>
          <div className="welcome_modal-wrapper-img">
            <img src={IconMail} alt="Mail" />
          </div>
          <h1 className="welcome_modal-wrapper-title">Welcome!</h1>
          <p className="welcome_modal-wrapper-desc">
            We want to get to know you better in order to provide you with a
            personalized dashboard that best suits all your needs.
          </p>

          <div className="welcome_modal-wrapper-footer">
            <button
              onClick={onClose}
              className="yellow-theme-btn button__element_button welcome_modal-wrapper-footer-btn"
            >
              <p className="button__text">Get Started</p>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default WelcomeModal;
