import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Typography, Tabs, Tab } from "@mui/material";

import PlanDialog from "./Dialogs/PlanDialog";
import PrivateLayout from "../../Layouts/PrivateLayout";
import PlanCard from "./Component/PlanCard";
import { ESTATE_PLANING_LIST, ESTATE_SETTLEMENT_LIST } from "../../Assets/Consts/plan";
import "./style.scss";

const TAB_KEY_ESTATE_PLANING = "TAB_KEY_ESTATE_PLANING";
const TAB_LABEL_ESTATE_PLANING = "Estate Planning";
const TAB_KEY_ESTATE_SETTLEMENT = "TAB_KEY_ESTATE_SETTLEMENT";
const TAB_LABEL_ESTATE_SETTLEMENT = "Estate Settlement";

const Default = () => {
  const account = useSelector((state) => state.account);
  const { sessionInfo } = useSelector((state) => state.account);

  const [plan, setPlan] = useState();
  const [activeTab, setActiveTab] = useState(TAB_KEY_ESTATE_PLANING);

  const handleChoosePlan = (planItem) => {
    if (planItem?.id === 'post-death-free') {}
    else if (planItem?.id === 'plan-free') {}
    else setPlan(planItem);
  };

  const isIn24Hr = useMemo(() => {
    if (!account?.user?.created_at) return false;
    const createdDate = new Date(account?.user?.created_at || null).getTime();
    const currentDate = new Date().getTime();
    return currentDate - createdDate < 3600 * 1000 * 24;
  }, [account?.user?.created_at]);

  const planList = useMemo(() => {
    if (activeTab === TAB_KEY_ESTATE_PLANING) return ESTATE_PLANING_LIST;
    return ESTATE_SETTLEMENT_LIST;
  }, [activeTab]);

  console.log("Session data: ", sessionInfo);

  return (
    <PrivateLayout>
      <Container maxWidth="lg">
        <Box mb={8} pt={4}>
          <Typography variant="h3" align="center">
            Select plan that suits you best
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mb: "40px" }}>
          <Tabs
            value={activeTab}
            sx={{
              borderRadius: "30px",
              background: "#F2F7FF",
              border: "1px solid #E6E6E6",
              "& .MuiTabs-indicator": {
                display: "none"
              }
            }}
            onChange={(evt, nextVal) => setActiveTab(nextVal)}
          >
            <Tab
              sx={{
                border: "none",
                borderRadius: "30px",
                fontSize: "18px",
                fontWeight: "600",
                padding: "0px 20px",
                color: "#545454",
                textTransform: "capitalize",

                "&.Mui-selected": {
                  background: "#4B6EAE",
                  color: "#ffffff"
                }
              }}
              label={TAB_LABEL_ESTATE_PLANING}
              value={TAB_KEY_ESTATE_PLANING}
            />
            <Tab
              sx={{
                border: "none",
                borderRadius: "30px",
                fontSize: "18px",
                fontWeight: "600",
                padding: "0px 20px",
                color: "#545454",
                textTransform: "capitalize",

                "&.Mui-selected": {
                  background: "#4B6EAE",
                  color: "#ffffff"
                }
              }}
              label={TAB_LABEL_ESTATE_SETTLEMENT}
              value={TAB_KEY_ESTATE_SETTLEMENT}
            />
          </Tabs>
        </Box>

        <Box
          sx={
            activeTab === TAB_KEY_ESTATE_PLANING
              ? {
                  gap: "20px",
                  display: "grid",
                  gridTemplateColumns: {
                    sx: "1fr",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)"
                  }
                }
              : {
                  gap: "20px",
                  display: "grid",
                  gridTemplateColumns: {
                    sx: "1fr",
                    lg: "repeat(3, 1fr)"
                  }
                }
          }
        >
          {planList.map((plan, index) => (
            <PlanCard
              key={`planlist${index}`}
              plan={plan}
              index={index}
              isIn24Hr={isIn24Hr}
              handleChoosePlan={handleChoosePlan}
            />
          ))}
        </Box>

        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Typography variant="h7" color="primary.dark">
            Have any questions? Contact the Peacefully Team. <br />
            Email: hello@peacefully.com <br />
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h7" color="grey.600">
              While our team includes attorneys and advisors we are not your
              attorney or advisor, even if you purchase a plan. <br />
              Our team is there to answer questions and help you complete tasks.
            </Typography>
          </Box>
        </Box>
      </Container>

      {!!plan && (
        <PlanDialog choosePlan={plan} handleClose={() => setPlan(null)} />
      )}
    </PrivateLayout>
  );
};

export default Default;
