import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PUBLIC_URLS } from "../constants";

const Private = ({ redirect = "/", children }) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useSelector((state) => state.account);

  if (!PUBLIC_URLS.includes(pathname) && !isAuthenticated) {
    return <Navigate to={redirect} replace />;
  }

  return children ? children : <Outlet />;
};

export default Private;
