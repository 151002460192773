import React from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import iconLock from "../../Assets/Images/icon_lock.svg";

const AccessDialog = ({ owner, handleClose, handleRequestAccess }) => {

  return (
    <Dialog
      maxWidth="xs"
      open={true}
      PaperProps={{ sx: { borderRadius: 8, overflow: "hidden" } }}
      onClose={handleClose}
    >
      <Box position="absolute" top="20px" right="20px">
        <CloseIcon
          sx={{ fontSize: 32, cursor: "pointer" }}
          color="#000000"
          onClick={() => handleClose()}
        />
      </Box>

      <Box px={6} py={5}>
        <Box display="flex" justifyContent="center" mb={2}>
          <Box
            component="img"
            src={iconLock}
            sx={{ width: "100px", mb: 2 }}
          />
        </Box>

        <Typography variant="h5" align="center" sx={{ mb: 4 }}>
          You don't have access to this section of {owner.first_name} {owner.last_name}'s dashboard.
        </Typography>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 8 }}
            onClick={handleRequestAccess}
          >
            Request Access
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AccessDialog;
