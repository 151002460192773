import mixpanel from "mixpanel-browser";
import { isCompanyEmail } from "./utils";

mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN);

let actions = {
  identify: (id) => {
    if(!isCompanyEmail(id)) {
      mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if(!isCompanyEmail(id)) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if(!isCompanyEmail(props?.email)) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props) => {
      if(!isCompanyEmail(props.email)) {
        mixpanel.people.set(props);
      }
    },
  },
};

export default actions;
