export const isCompanyEmail = (email) => {
  if (String(email)?.toLowerCase()?.endsWith('peacefully.io')) return true;
  if (String(email)?.toLowerCase()?.endsWith('peacefully.com')) return true;
  return false;
}

export const wait = async (seconds) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds)
  })
};
