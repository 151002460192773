import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PrivateLayout from "../../Layouts/PrivateLayout";
import { AddCollaboratorModal } from "../../Components/modals";
import SuccessModal from "../../Components/modals/Success";
import { listCollaboratorsByOwner } from "../../Services/User";
import { useSelector } from "react-redux";
import Loading from "../../Layouts/Loading";

const Collaborators = () => {
  const account = useSelector((state) => state.account);

  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [collaboratorList, setCollaboratorList] = useState([]);

  useEffect(() => {
    handleLoadData();
    // eslint-disable-next-line
  }, []);

  const handleLoadData = async () => {
    setLoading(true);
    try {
      const result = await listCollaboratorsByOwner({
        ownerEmail: account?.user?.email
      });
      setCollaboratorList(result?.data?.data || []);
    } catch (error) {
      console.log("failed api ---", error);
    }
    setLoading(false);
  };

  return (
    <PrivateLayout>
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          p: {
            xs: "15px",
            sm: "40px 20px",
            md: "20px",
            lg: "38px"
          }
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: {
              xs: "18px",
              sm: "28px"
            },
            mb: {
              xs: "15px",
              sm: "20px"
            }
          }}
        >
          Collaborators
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography color="grey.450">
            Admins have the capability to add and manage collaborators, whereas
            viewers are granted access solely for viewing purposes, without any
            editing privileges.
          </Typography>
          <Button
            size="large"
            variant="contained"
            startIcon={<PersonAddAlt1Icon />}
            sx={{ whiteSpace: "nowrap", padding: "10px 40px" }}
            onClick={() => setAddOpen(true)}
          >
            Add Collaborators
          </Button>
        </Box>
        <Box>
          <TableContainer component={Paper} sx={{ mt: "50px" }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell width="30%">COLLABORATOR</TableCell>
                  <TableCell width="30%">Email</TableCell>
                  <TableCell width="40%">ADDED BY</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collaboratorList.map((owner, index) => (
                  <TableRow key={index}>
                    <TableCell width="30%">
                      {owner.deputy_first_name} {owner.deputy_last_name}
                    </TableCell>
                    <TableCell width="30%">{owner.deputy_email}</TableCell>
                    <TableCell width="40%">{owner.owner_email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <AddCollaboratorModal
        open={addOpen}
        onClose={() => setAddOpen(false)}
        onOk={() => {
          setAddOpen(false);
          setSuccessOpen(true);
          handleLoadData();
        }}
      />
      <SuccessModal
        title="Collaborator Added"
        description="Johnson Downy has been added as a collaborator."
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        onOk={() => setSuccessOpen(false)}
      />
      {loading && <Loading />}
    </PrivateLayout>
  );
};

export default Collaborators;
