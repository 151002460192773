import React from "react";
import IconCheckWhite from "../../Assets/Images/icon_check_white.svg";
import { Box } from "@mui/material";

const PlanFeature = ({ features }) => {
  return (
    <Box sx={{ mb: "10px" }}>
      {features.map((feature, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              border: "1px solid #ECECEC;",
              padding: "8px 8px 8px 10px",
              borderRadius: "10px",
              alignItems: "center",
              marginBottom: "10px"
            }}
            key={`feature_item_${index}`}
          >
            <Box
              sx={{
                minWidth: "16px",
                height: "16px",
                background: "#3f8678",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px"
              }}
            >
              <img src={IconCheckWhite} alt="Plan Check" />
            </Box>
            <Box
              sx={{
                color: "#545454",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "25px"
              }}
            >
              {feature}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PlanFeature;
