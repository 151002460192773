import React from "react";
import Review from "../Home/Reviews";
import PublicLayout from "../../Layouts/PublicLayout";
import SectionService from "../Home/SectionService";
import SectionFamily from "../Home/SectionFamily";
import HeroGetStarted from "./HeroGetStarted";

const GetStarted = () => {
  return (
    <PublicLayout>
      <HeroGetStarted />
      <SectionService />
      <Review />
      <SectionFamily />
    </PublicLayout>
  );
};

export default GetStarted;
