import React, { useState } from "react";
import { Box, Button, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Input from "../../Form/Input";
import FormContainer from "../../Form/FormContainer";
import IconEmailConfirm from "../../../Assets/Images/icon_mail.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendRequest } from "../../../Services/User";
import { getSessionInfoStart } from "../../../Redux-Saga/Redux/account";

const ReportDeathModal = ({ open, owner, onClose, onOk }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token, sessionInfo } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const userId = sessionInfo?.currentUser?.id;

  const handleReportDeath = async () => {
    try {
      setLoading(true);
      const { email, owner_id, qualtrics_user_id: qualtricsUserId } = owner;

      const payload = {
        qualtricsUserId,
        ownerEmail: email,
        owner_id,
        deputy_id: userId
      };

      await sendRequest({ path: "/auth/hrAccessRequest", method: "post", payload });
      dispatch(getSessionInfoStart(token));
    } catch (err) {
      console.log('Failed to report a death: ', err);
    } finally {
      setLoading(false);
      onOk();
    }
  }

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 4 } }}
    >
      {showConfirm ? (
        <Box px={3} py={8}>
          <Box position="absolute" top="20px" right="20px">
            <CloseIcon
              sx={{ fontSize: 32, cursor: "pointer" }}
              color="#000000"
              onClick={() => onClose()}
            />
          </Box>

          {loading && (
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="#ddd"
              sx={{ opacity: 0.5, zIndex: 999 }}
            >
              <CircularProgress size={40} />
            </Box>
          )}

          <Box display="flex" justifyContent="center" my={2.5}>
            <img
              width={100}
              height={100}
              src={IconEmailConfirm}
              alt="Email Confirmation"
            />
          </Box>

          <Box mb={4}>
            <Typography
              variant="h2"
              align="center"
              color="primary.dark"
              fontWeight={600}
            >
              Email Confirmation
            </Typography>
          </Box>

          <Box px={2} mb={5}>
            <Typography align="center" color="grey.700">
              We've received your report and have sent an email to Jane Wong.
              Jane will have up to 48 hours to refuse the report of her/his death.
              After 48 hours, you'll be granted full access.
            </Typography>
          </Box>

          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid xs={12} sm={8}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={handleReportDeath}
              >
                Okay
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <FormContainer>
          <Box px={3} py={8}>
            <Box position="absolute" top="20px" right="20px">
              <CloseIcon
                sx={{ fontSize: 32, cursor: "pointer" }}
                color="#000000"
                onClick={() => onClose()}
              />
            </Box>

            <Box mb={4}>
              <Typography
                variant="h2"
                align="center"
                color="primary.dark"
                fontWeight={600}
              >
                Report A Death
              </Typography>
            </Box>

            <Box px={2} mb={5}>
              <Typography align="center" color="grey.700">
                We are so sorry to hear about your loss. Please answer the
                following questions to continue reporting a death.
              </Typography>
            </Box>

            <Box mb={3}>
              <Input
                fullWidth
                name="dateDeath"
                variant="filled"
                label="Date of Passing"
              />
            </Box>
            <Box mb={5}>
              <Input
                fullWidth
                name="dateDeathConfirm"
                variant="filled"
                label="Confirm Date of Passing"
              />
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid xs={12} sm={8}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => setShowConfirm(true)}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Box>
        </FormContainer>
      )}
    </Dialog>
  );
};

export default ReportDeathModal;
