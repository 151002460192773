import React, { useRef } from "react";
import Slider from "react-slick";
import { data } from "../Data";
import "./reviews_old.scss";
import IconBack from "../../../Assets/Images/icon_back.svg";
import IconNext from "../../../Assets/Images/icon_next.svg";
import { Box, Container } from "@mui/material";

const Default = () => {
  const sliderRef = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSlideNext = () => {
    sliderRef.current.slickNext();
  };

  const handleSlideBack = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="customer_reviews">
      <Container>
        <div className="customer_reviews-header">Customer Reviews</div>

        {data.customerReviews && (
          <div className="customer_reviews-slider">
            <div
              className="customer_reviews-slider-btn"
              onClick={handleSlideBack}
            >
              <img src={IconBack} alt="" />
            </div>
            <div className="customer_reviews-slider-wrapper">
              <Slider ref={sliderRef} className="reviews_slider" {...settings}>
                {data.customerReviews.map((data, index) => (
                  <div
                    className="reviews_grid"
                    key={`customer_review_${index}`}
                  >
                    <Box p={2}>
                      <Box display="flex" alignItems="center" className="rev_profile">
                        <div className="">
                          <h4>{data.name}</h4>
                          <Box display="flex" alignItems="center" className="rev_star_rating">
                            {[...Array(data.count)].map((e, i) => (
                              <div key={`rating-${i}`}>
                                <i className="fa fa-star"></i>
                              </div>
                            ))}
                          </Box>
                        </div>
                      </Box>
                      <div className="rev_content">
                        <p>{data.description}</p>
                      </div>
                    </Box>
                  </div>
                ))}
              </Slider>
            </div>

            <div
              className="customer_reviews-slider-btn"
              onClick={handleSlideNext}
            >
              <img src={IconNext} alt="" />
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

export default Default;
