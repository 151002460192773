import React from "react";
import "./style.scss";
import { data } from "../Data";
import { Link } from "react-router-dom";
import { Container, Button } from "@mui/material";

export default function SectionFamily() {
  return (
    <div className="family">
      <Container>
        <div className="family-sub-title">Unburden Family</div>
        <div className="family-title">
          We’ll save your time so you can focus on what truly matters
        </div>

        <div className="family_container">
          {data.features.map((data, index) => {
            const isActive = index === 1;
            return (
              <div
                className={`family_container-card ${isActive ? "active-card" : ""}`}
                key={index}
              >
                <img
                  className="family_container-card-img"
                  src={data.image.default}
                  alt="Family"
                />
                <h5 className="family_container-card-title">{data.name}</h5>
                <p className="family_container-card-desc">{data.description}</p>
                {isActive && (
                  <Button variant="outlined"
                    size="large"
                    component={Link}
                    sx={{ px: 8, mt: 4 }}
                    to={"/auth/signup"}
                  >
                    Get Started
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
