import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bgcolor="#fbfbfb80"
      zIndex="999"
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
