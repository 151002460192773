import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useIdleTimer } from 'react-idle-timer'
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import WithAxios from "./Services/WithAxios";
import Router from "./Routes";
import { encryptStorage } from "./Components/Helpers/commonMethods";
import { getLogout, getSessionInfoStart, setReferer } from "./Redux-Saga/Redux/account";
import Cookies from "js-cookie";
import { initializeTagManager } from "./gtm";
import mainTheme from "./themes/main";

import "react-toastify/dist/ReactToastify.css";
import "animate.css";

const App = () => {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [startSession, setStartSession] = useState(false);

  useEffect(() => {
    if (account.isAuthenticated && startSession) {
      encryptStorage.setItem("token", account.token);
      encryptStorage.setItem("user", account.user);
      dispatch(getSessionInfoStart(account.token));
    }
    // eslint-disable-next-line
  }, [account.isAuthenticated, startSession]);

  useEffect(() => {
    const referrer = Cookies.get('referrer');
    let referUrl = document.referrer;
    if (referUrl.includes('localhost')) referUrl = 'https://peacefully-demo.herokuapp.com/';
    if (!referrer) {
      Cookies.set('referrer', referUrl, { expires: 30 });
    } else {
      referUrl = referrer;
    }

    dispatch(setReferer(referUrl));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initializeTagManager();
  }, []);

  useIdleTimer({
    onIdle: () => dispatch(getLogout()),
    timeout: 1800_000,
    throttle: 500
  });

  // Logout after 15 mins of inactivity, even though they closed the browsers
  window.onload = () => {
    let tabCount = localStorage.getItem("tabCount");
    if (!tabCount) tabCount = 0;
    tabCount = Number(tabCount) + 1;
    localStorage.setItem("tabCount", tabCount);

    // Check if it exceeds expiration time
    if (tabCount <= 1) {
      const closedData = localStorage.getItem("closingTime");
      const closingTime = closedData ? new Date(Number(closedData)).getTime() : new Date().getTime()
      const currentTime = new Date().getTime();
      const delta = currentTime - closingTime;
      if (delta > 15 * 60 * 1000) dispatch(getLogout());
      else setStartSession(true);
    } else {
      setStartSession(true);
    }
  };

  window.onbeforeunload = () => {
    const tabCountData = localStorage.getItem("tabCount");
    let tabCount = tabCountData ? Number(tabCountData) : 1;

    tabCount --;
    localStorage.setItem("tabCount", tabCount);

    if (tabCount <= 0) {
      const currentTime = new Date().getTime();
      localStorage.setItem("closingTime", currentTime);
    }
  };

  return (
    <WithAxios>
      <BrowserRouter>
        <ToastContainer icon={true} />
          <ThemeProvider theme={mainTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ProSidebarProvider>
              <Router />
            </ProSidebarProvider>
            </LocalizationProvider>
          </ThemeProvider>
      </BrowserRouter>
    </WithAxios>
  );
};

export default App;
