import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import * as Yup from "yup";
import IconTwoStep from "../../../Assets/Images/icon_two_step.svg";
import FormContainer from "../../../Components/Form/FormContainer";
import Input from "../../../Components/Form/Input";

const VerificationModal = ({
  handleClose,
  onSubmit,
  resendCode,
  loading,
}) => {
  const validationSchema = Yup.object().shape({
    code: Yup
      .string()
      .required("Code is required")
      .matches(/^\d{6}$/, "Code must be a six-digit number"),
  });

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="xs" PaperProps={{ sx: { borderRadius: 4 } }}>
      <Box position="relative" sx={{ maxWidth: 500, px: 3, py: 6 }}>
        <Box
          display="flex"
          position="absolute"
          top={0}
          left={0}
          right={0}
          p={2}
          justifyContent="flex-end"
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: 32 }} />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
          <img src={IconTwoStep} alt="Veification" />
        </Box>

        <Typography
          variant="h2"
          align="center"
          mb={3}
          color="#000"
        >
          Two-Step Verification
        </Typography>

        <Typography
          align="center"
          mb={5}
          color="#545454"
        >
          To help keep your account safe, please enter the code sent to your phone.
        </Typography>

        <FormContainer
          defaultValues={{ code: "" }}
          validationSchema={validationSchema}
          onSuccess={({ code }) => onSubmit(code)}
        >
          <Box>
            <Box mb={3}>
              <Input
                fullWidth
                name="code"
                label="Verification Code"
                variant="filled"
              />
            </Box>

            <Box display="flex" alignItems="center" mb={4}>
              <Typography sx={{ mr: 0.5 }}>{`Did not receive code? `}</Typography>
              <Button sx={{ fontSize: 16, textDecoration: 'underline' }} variant="text" onClick={resendCode}>
                Resend Code
              </Button>
            </Box>

            <Box px={8}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
                size="large"
              >
                Continue
              </LoadingButton>
            </Box>
          </Box>
        </FormContainer>
      </Box>
    </Dialog>
  );
};

export default VerificationModal;
