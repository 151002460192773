import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { sendRequest } from "../../Services/User";
import { getSessionInfoStart } from "../../Redux-Saga/Redux/account";
import ReportDeathModal from "../../Components/modals/ReportDeath";

const DeputyList = () => {
  const account = useSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(false);
  const [deadUser, setDeadUser] = useState();
  const dispatch = useDispatch();
  const { sessionInfo: { myDeputies = [], assignUserList = [] } = {} } = account;

  const deputyList = useMemo(() => {
    try {
      return JSON.parse(JSON.stringify(myDeputies)).sort((a, b) => Number(a.deputy_order) - Number(b.deputy_order));
    } catch (e) {
      console.log("Updating Deputy Error: ", e);
    }
  }, [myDeputies]);

  // reject request
  const rejectRequest = async (deputyId) => {
    try {
      setIsLoading(true);
      await sendRequest({
        path: `/auth/hrAccessRequestReject/${deputyId}`,
        method: "get",
      });
      dispatch(getSessionInfoStart(account.token));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const {
        response: { data },
      } = err;
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("Reject Request Failed: ", err);
    }
  };

  // accept request
  const acceptRequest = async (deputyId) => {
    try {
      setIsLoading(true);
      await sendRequest({
        path: `/auth/hrAccessRequestAccept/${deputyId}`,
        method: "get",
      });
      dispatch(getSessionInfoStart(account.token));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const {
        response: { data },
      } = err;
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // delete deputy
  const deleteDeputy = async (deputyId) => {
    try {
      setIsLoading(true);
      await sendRequest({
        path: `/auth/delete-deputy/${deputyId}`,
        method: "get",
      });
      dispatch(getSessionInfoStart(account.token));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const {
        response: { data },
      } = err;
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleOpenReport = (owner) => {
    setDeadUser(owner);
  };

  useEffect(
    () => {
      dispatch(getSessionInfoStart(account.token));
    },
    // eslint-disable-next-line
    []
  );

  if (isLoading) {
    return (
      <Box p={4} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box py={3}>
      <Box mb={1}>
        <Typography variant="h6" mb={1}>
          You are a deputy of these users
        </Typography>
        <Typography variant="h7" color="grey.700" sx={{ fontWeight: "400" }}>
          You can request to get the access to the full dashboard of these users
        </Typography>
      </Box>

      {assignUserList.length > 0 ? (
        <TableContainer component={Paper} sx={{ mb: "100px" }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Name</TableCell>
                <TableCell width="30%">Email</TableCell>
                <TableCell width="20%">Full Access Status</TableCell>
                <TableCell width="30%" align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {assignUserList.map((owner, index) => (
                <TableRow key={index}>
                  <TableCell width="20%">{owner.first_name} {owner.last_name}</TableCell>
                  <TableCell width="30%">{owner.email}</TableCell>
                  <TableCell width="20%">{owner.is_access}</TableCell>
                  <TableCell width="30%">
                    <Box width="100%" display="flex" justifyContent="flex-end">
                      <Button variant="outlined" onClick={() => handleOpenReport(owner)}>
                        Report A Death
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box pb={4}>
          <Typography variant="h7" color="grey.700" sx={{ fontWeight: 400 }}>
            There are no users who shared their own dashboard with you.
          </Typography>
        </Box>
      )}

      <Box mb={1}>
        <Typography variant="h6" mb={1}>
          Your Deputies
        </Typography>
        <Typography variant="h7" color="grey.700" sx={{ fontWeight: "400" }}>
          Deputies can access to your dashboard. Deputies are only given access based on your preferences.
        </Typography>
      </Box>

      {deputyList?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Name</TableCell>
                <TableCell width="30%">Email</TableCell>
                <TableCell width="20%">Full Access Status</TableCell>
                <TableCell width="30%" align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myDeputies.map((myDeputy, index) => (
                <TableRow key={index}>
                  <TableCell width="20%">
                    {myDeputy.first_name} {myDeputy.last_name}
                  </TableCell>
                  <TableCell width="30%">{myDeputy.email}</TableCell>
                  <TableCell width="20%">{myDeputy.is_access}</TableCell>
                  <TableCell>
                    <Box width="100%" display="flex" justifyContent="flex-end">
                      <MenuBtn
                        deputy={myDeputy}
                        rejectRequest={rejectRequest}
                        acceptRequest={acceptRequest}
                        deleteDeputy={deleteDeputy}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box pb={4}>
          <Typography variant="h7" color="grey.700" sx={{ fontWeight: 400 }}>
            You don't have any deputies.
          </Typography>
        </Box>
      )}

      {!!deadUser && (
        <ReportDeathModal
          owner={deadUser}
          open={true}
          onClose={() => setDeadUser()}
          onOk={() => setDeadUser()}
        />
      )}
    </Box>
  );
};

const MenuBtn = ({ deputy, rejectRequest, acceptRequest, deleteDeputy }) => {
  const [menuAnchor, setMenuAnchor] = useState();

  return (
    <>
      <Button
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        size="small"
        variant="contained"
        color="primary"
      >
        Actions
      </Button>

      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setMenuAnchor(null)}
      >
        <Box py={1}>
          {deputy.is_access === "Requested full access" && (
            <MenuItem onClick={() => rejectRequest(deputy.id)}>Reject</MenuItem>
          )}

          {deputy.is_access === "Requested full access" && (
            <MenuItem onClick={() => acceptRequest(deputy.id)}>Accept</MenuItem>
          )}

          <MenuItem onClick={() => deleteDeputy(deputy.id)}>
            Delete Deputy
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default DeputyList;
