import moment from "moment";
export const pluralize = (val, word, plural = word + "s") => {
  const _pluralize = (num, word, plural = word + "s") =>
    [1, -1].includes(Number(num)) ? `${val} ${word}` : `${val} ${plural}`;
  if (typeof val === "object")
    return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};

export const daysRemaining = (date) => {
  // console.log("date", date);
  let eventDate = moment(date);
  let todaysDate = moment();
  const d = eventDate.diff(todaysDate, "days");
  return d > 0 ? d : 0;
};

export const formatDate = (date) => {
  return moment(date).format('DD / MM / YYYY');
}

export const getDomainName = (url) => {
  if (!url) return '';
  return url.replace(/^https?:\/\//, "")
    .replace(/^www\./, "")
    .replace(/\..*/, '');
}
