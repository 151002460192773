import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../../../Layouts/Loading";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CSVLink } from "react-csv";

const FILTER_NEW = "FILTER_NEW";
const FILTER_LAST = "FILTER_LAST";

const ActivityLog = () => {
  const [filter, setFilter] = useState(FILTER_NEW);
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (user?.email) {
      handleLoadData();
    }
    // eslint-disable-next-line
  }, [user?.email]);

  const handleLoadData = async () => {
    setLoading(true);
    try {
      const result = await axios.get("https://mixpanel.com/api/query/engage", {
        params: {
          project_id: process.env.REACT_APP_MIX_PANEL_PROJECT_ID,
        },
        auth: {
          username: process.env.REACT_APP_MIX_PANEL_API_SECRET,
          password: "",
        },
      });

      const finedItem = (result?.data?.results || []).find(
        (item) => item.$properties?.Email === user.email
      );
      if (finedItem) {
        handleLoadActivity(finedItem.$distinct_id);
      }
      setLoading(false);
    } catch (error) {
      console.log("log event error ---", error);
      setLoading(false);
    }
  };

  const handleLoadActivity = async (distinct_id) => {
    setLoading(true);
    try {
      const result = await axios.get(
        "https://mixpanel.com/api/query/stream/query",
        {
          params: {
            project_id: process.env.REACT_APP_MIX_PANEL_PROJECT_ID,
            distinct_ids: JSON.stringify([distinct_id]),
            from_date: "2022-10-01",
            to_date: moment().format("YYYY-MM-DD"),
          },
          auth: {
            username: process.env.REACT_APP_MIX_PANEL_API_SECRET,
            password: "",
          },
        }
      );
      setLogData(
        (result?.data?.results?.events || []).filter(
          (item) => item.event !== "$identify"
        )
      );
      setLoading(false);
    } catch (error) {
      console.log("log event error ---", error);
      setLoading(false);
    }
  };

  // const eventData = useMemo(() => {
  //   if (filter === FILTER_LAST) {
  //     return logData.sort((itemA, itemB) => {
  //       if (
  //         itemA?.properties?.mp_processing_time_ms >
  //         itemB?.properties?.mp_processing_time_ms
  //       ) {
  //         return 1;
  //       } else {
  //         return -1;
  //       }
  //     });
  //   } else {
  //     return logData.sort((itemA, itemB) => {
  //       if (
  //         itemA?.properties?.mp_processing_time_ms >
  //         itemB?.properties?.mp_processing_time_ms
  //       ) {
  //         return -1;
  //       } else {
  //         return 1;
  //       }
  //     });
  //   }
  // }, [filter, logData]);

  const headers = [
    { label: "User", key: "user" },
    { label: "Date", key: "date" },
    { label: "Activity", key: "activity" },
  ];

  const reportData = useMemo(() => {
    const data = (logData || []).map((item) => ({
      user: `${item?.properties?.firstName} ${item?.properties?.lastName}`,
      date: moment(item?.properties?.mp_processing_time_ms).format(
        "MM/DD/YYYY"
      ),
      activity: item?.event,
    }));

    return data;
  }, [logData]);

  return (
    <Box py={3}>
      <Typography variant="h6" mb={3}>
        Most Recent Activity
      </Typography>

      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          sx={
            filter === FILTER_NEW
              ? {
                  background: "#E1E1E1",
                  color: "#6D6D6D",
                  px: "16px",
                  fontWeight: "700",
                }
              : { color: "#545454", px: "16px", fontWeight: "700" }
          }
          onClick={() => setFilter(FILTER_NEW)}
        >
          Newest First
        </Button>
        <Button
          sx={
            filter === FILTER_LAST
              ? {
                  background: "#E1E1E1",
                  color: "#6D6D6D",
                  px: "16px",
                  fontWeight: "700",
                }
              : { color: "#545454", px: "16px", fontWeight: "700" }
          }
          onClick={() => setFilter(FILTER_LAST)}
        >
          Oldest First
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="40%">User</TableCell>
              <TableCell width="20%">Date</TableCell>
              <TableCell width="20%">Activity</TableCell>
              <TableCell width="20%">
                <CSVLink
                  data={reportData}
                  filename="my-log.csv"
                  headers={headers}
                >
                  <Button variant="contained">Download Data</Button>
                </CSVLink>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logData.map((logItem, index) => (
              <TableRow key={`key-row-${index}`}>
                <TableCell width="40%" component="th" scope="row">
                  {logItem?.properties?.firstName}{" "}
                  {logItem?.properties?.lastName}
                </TableCell>
                <TableCell width="20%">
                  {moment(logItem?.properties?.mp_processing_time_ms).format(
                    "MM/DD/YYYY"
                  )}
                </TableCell>
                <TableCell width="20%">{logItem?.event}</TableCell>
                <TableCell width="20%"></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {loading && <Loading />}
    </Box>
  );
};

export default ActivityLog;
