import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { data } from "../Data";
import IconBack from "../../../Assets/Images/icon_back.svg";
import IconNext from "../../../Assets/Images/icon_next.svg";
import { Box, Button, Container } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./reviews.scss";

const Default = () => {
  const sliderRef = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSlideNext = () => {
    sliderRef.current.slickNext();
  };

  const handleSlideBack = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="customer_reviews">
      <Container>
        <div className="customer_reviews-sub-header">Testimonials</div>
        <div className="customer_reviews-head">
          Why people choose Peacefully
        </div>

        {data.customerReviews && (
          <div className="customer_reviews-slider">
            <div
              className="customer_reviews-slider-btn"
              onClick={handleSlideBack}
            >
              <img src={IconBack} alt="" />
            </div>
            <Box className="customer_reviews-slider-wrapper">
              <Slider ref={sliderRef} className="reviews_slider" {...settings}>
                {data.customerReviews.map((data, index) => (
                  <Box
                    className="customer_reviews-slider-wrapper-item"
                    key={`customer_review_${index}`}
                  >
                    <Box p={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        className="rev_star_rating"
                      >
                        {[...Array(data.count)].map((e, i) => (
                          <Box key={`rating-${i}`}>
                            <i className="fa fa-star"></i>
                          </Box>
                        ))}
                      </Box>
                      <div className="customer_reviews-slider-wrapper-item-content">
                        <p>"{data.description}"</p>
                      </div>
                      <div className="customer_reviews-slider-wrapper-item-footer">
                        <p>{data.name}</p>
                      </div>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>

            <div
              className="customer_reviews-slider-btn"
              onClick={handleSlideNext}
            >
              <img src={IconNext} alt="" />
            </div>
          </div>
        )}
        <div className="customer_reviews-footer">
          <Button
            variant="outlined"
            size="large"
            component={Link}
            sx={{ px: 8 }}
            to={"/auth/signup"}
          >
            Get Started
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default Default;
