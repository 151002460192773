import React, { useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import iconLock from "../../Assets/Images/icon_lock.svg";
import { sendRequest } from "../../Services/User";
import { getSessionInfoStart } from "../../Redux-Saga/Redux/account";

const NotificationDialog = ({ deputy, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const handleAcceptRequest = async () => {
    try {
      setIsLoading(true);
      await sendRequest({
        path: `/auth/hrAccessRequestAccept/${deputy.id}`,
        method: "get",
      });
      dispatch(getSessionInfoStart(account.token));
      onClose();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const { response: { data } } = err;
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleRejectRequest = async () => {
    try {
      setIsLoading(true);
      await sendRequest({
        path: `/auth/hrAccessRequestReject/${deputy.id}`,
        method: "get",
      });
      dispatch(getSessionInfoStart(account.token));
      onClose();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const { response: { data } } = err;
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("Reject Request Failed: ", err);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      open={true}
      PaperProps={{ sx: { borderRadius: 8, overflow: "hidden" } }}
      onClose={onClose}
    >
      <Box position="absolute" top="20px" right="20px">
        <CloseIcon
          sx={{ fontSize: 32, cursor: "pointer" }}
          color="#000000"
          onClick={() => onClose()}
        />
      </Box>

      <Box px={3} py={5}>
        <Box display="flex" justifyContent="center" mb={2}>
          <Box
            component="img"
            src={iconLock}
            sx={{ width: "100px", mb: 2 }}
          />
        </Box>

        <Typography variant="h5" align="center" sx={{ mb: 4 }}>
          {deputy?.first_name} {deputy?.last_name} requested to get full access of your dashboard
        </Typography>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 8, mr: 4 }}
            onClick={handleAcceptRequest}
            disabled={isLoading}
          >
            Accept
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ px: 8 }}
            onClick={handleRejectRequest}
            disabled={isLoading}
          >
            Reject
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default NotificationDialog;
