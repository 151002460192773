import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import footerLogo from "../../../Assets/Images/new/logo.png";
import mobileIcon from "../../../Assets/Images/new/Smartphone.png";
import EmailIcon from "../../../Assets/Images/new/email-icon.png";

import "./footer_old.scss";
import RequestModal from "../../Home/modal/RequestModal";
import { CONTACT_PHONE } from "../../../Assets/Consts/contact";

const Footer = ({ signInUrl }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const dt = new Date();

  return (
    <>
      <footer className="footer_old">
        <Container className="footer-old">
          <Grid container>
            <div className="footer_logo">
              <Link to="/">
                <img src={footerLogo} alt="not found" />
              </Link>
            </div>
          </Grid>

          <Grid container>
            <Grid item md={5}>
              <div className="footer_widgets">
                <h3>Contact</h3>
                <ul className="list-styled-none footer_contact_info">
                  <li>
                    <img src={mobileIcon} alt="not found" />{" "}
                    <a href={`tel: ${CONTACT_PHONE}`}>{CONTACT_PHONE}</a>
                  </li>
                  <li>
                    <img src={EmailIcon} alt="not found" />{" "}
                    <a href="mailto: hello@peacefully.io">
                      hello@peacefully.io
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item md={5}>
              <div>
                <Grid container>
                  <Grid item md={5} className="footer_widgets">
                    <h3>Company</h3>
                    <ul className="list-styled-none featured_link">
                      <li>
                        <a href="https://guide.peacefully.com/">Resources</a>
                      </li>
                      <li>
                        <a href="mailto:hello@peacefully.com">Contact</a>
                      </li>
                      <li>
                        <div
                          className="request-demo"
                          onClick={(e) => {
                            e.preventDefault();
                            setDialogOpen(true);
                          }}
                        >
                          Request a Demo
                        </div>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={7} className="footer_widgets">
                    <h3>More Information</h3>
                    <ul className="list-styled-none featured_link">
                      <li>
                        <a href={signInUrl}>Login</a>
                      </li>
                      <li>
                        <a href="https://peacefully.com/terms-services/">Terms of Use</a>
                      </li>
                      <li>
                        <a href="https://peacefully.com/privacy-policy/">Privacy Policy</a>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="footer_widgets">
                <h3>Follow us</h3>
                <Box display="flex" className="social_media_footer">
                  <div>
                    <a
                      href="https://www.instagram.com/wearepeacefully"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i style={{ color: "#FB3958" }} className="fa fa-instagram"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/peacefullyio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/company/peacefully"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <p className="footer_content">
                Peacefully.com is not a law firm and does not perform the same
                services provided by a licensed attorney. Peacefully.com, its
                services, and its forms and templates are not a substitute for
                the advice or service of a licensed attorney. We recommend
                that you seek out a local attorney for any legal questions
                and/or analysis.
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <div className="copyright_txt">
              <p>©Peacefully Inc. {dt.getFullYear()}</p>
            </div>
          </Grid>
        </Container>
      </footer>
      {dialogOpen && (
        <RequestModal dialogClose={() => setDialogOpen(false)} />
      )}
    </>
  );
};

export default Footer;
