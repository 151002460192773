const SUB_MENU_LIST = [
  "/plan",
  "/my-account",
  "/help",
  "/my-deputies",
  "/dashboard",
];

const SIGN_UP_METHOD_NORMAL = "SIGN_UP_METHOD_NORMAL";
const SIGN_UP_METHOD_FLOW = "SIGN_UP_METHOD_FLOW";

export { SUB_MENU_LIST, SIGN_UP_METHOD_NORMAL, SIGN_UP_METHOD_FLOW };
