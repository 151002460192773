import React from "react";
import { Dialog, Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconUpgrade from "../../../Assets/Images/icon_upgrade.svg";
import { Link } from "react-router-dom";

export default function UpgradePlanModal({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} sx={{ borderRadius: "10px" }}>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: 32 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: "70px 70px 40px 70px",
          }}
        >
          <Box component="img" src={IconUpgrade} sx={{ mb: "26px" }}></Box>
          <Typography variant="h2" color="primary.dark" sx={{ mb: "20px" }}>
            Upgrade to Advanced
          </Typography>
          <Typography
            variant="h6"
            color="grey.700"
            sx={{ mb: "60px", textAlign: "center", fontWeight: "400" }}
          >
            Upgrade your plan to Advanced to gain access to all planning and
            post-death modules as well as legal estate planning.
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/plan"
            size="large"
            sx={{ mb: "10px" }}
          >
            View Plan
          </Button>
          <Button onClick={onClose}>Not Now</Button>
        </Box>
      </Box>
    </Dialog>
  );
}
