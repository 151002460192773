export const CONTACT_SCHEDULE_LINK = "https://calendly.com/supportpeacefully/30min";
export const CONTACT_PHONE = "(805) 222-0118";
export const CONTACT_EMAIL = "hello@peacefully.com";

export const SIGNUP_SOURCE_FACEBOOK = "facebook";
export const LABEL_SOURCE_FACEBOOK = "Facebook AD";
export const SIGNUP_SOURCE_NEWSMAX = "NewsMax";
export const SIGNUP_SOURCE_HOSPICEFLYER = "hospiceflyer";
export const SIGNUP_SOURCE_FREDHUTCH = "fredhutch";
export const SIGNUP_SOURCE_GOOGLE = "google";
export const SIGNUP_SOURCE_LANDING = "landing";

export const SOURCE_MAP = {
  "Google": "google.com",
  "facebook": "facebook.com",
  "LinkedIn": "linkedin.com",
  "NewsMax": "newsmax",
  "Fredhutch": "fredhutch",
  "Landing": "landing",
  "Hospiceflyer": "hospiceflyer"
};
