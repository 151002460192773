import React, { useEffect, useState } from "react";
import Amplify from "aws-amplify";
import ChangePassword from "./ChangePassword";
import { toast } from "react-toastify";
import { getCsvFile, userChangePassword } from "../../Services/User";
import ProfileUpdate from "./ProfileUpdate";
import { useDispatch, useSelector } from "react-redux";
import {
  cognitoRequest,
  getLogout,
  getSessionInfoStart,
} from "../../Redux-Saga/Redux/account";
import PrivateLayout from "../../Layouts/PrivateLayout";
import { Box, Divider, Typography, Button } from "@mui/material";

import ProfilePlan from "./ProfilePlan";
import ProfileFooter from "./ProfileFooter";
import DeputyList from "../DeputyList";
import ActivityLog from "./ActivityLog";
import "./myaccount.scss";

const TAB_KEY_PROFILE = "TAB_KEY_PROFILE";
const TAB_KEY_MANAGE = "TAB_KEY_MANAGE";
const TAB_KEY_ACTIVITY = "TAB_KEY_ACTIVITY";

const Default = () => {
  const [csv, setCsv] = useState(null);
  const [loading, setLoading] = useState(false);
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const { token } = account;
  const [activeTab, setActiveTab] = useState(TAB_KEY_PROFILE);
  console.log("loading: ", loading);

  const getCsvStream = async (token) => {
    try {
      setLoading(true);
      const csvResult = await getCsvFile(token);
      setCsv(csvResult?.data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Failed to get csv download: ", err);
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(getSessionInfoStart(token));
      getCsvStream(token);
    }
  }, [token, dispatch]);

  const handleChangePassword = (oldPassword, newPassword) => {
    Amplify.Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("Current User", user);
        return Amplify.Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        console.log("Cognito change password", data);
        changeUserPassword(oldPassword, newPassword);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(cognitoRequest(false));
      });
  };

  const changeUserPassword = (oldPassword, newPassword) => {
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    userChangePassword(account.token, data)
      .then(function (res) {
        console.log("getUserChangePassword:", res);
        toast.success("Password Change Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(cognitoRequest(false));
        dispatch(getLogout());
      })
      .catch(function (error) {
        console.log(error);
        dispatch(cognitoRequest(false));
        toast.error(error.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSubmitChangePassword = (values) => {
    dispatch(cognitoRequest(true));
    handleChangePassword(values.oldPassword, values.newpassword);
  };

  return (
    <PrivateLayout>
      <Box flex={1} className="my_account">
        <Typography variant="h3" mb={4}>
          Account Settings
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            borderBottom: "1px solid #E7E7E7",
          }}
          py={2.5}
        >
          <Button
            size="large"
            variant={activeTab === TAB_KEY_PROFILE ? "contained" : ""}
            onClick={() => setActiveTab(TAB_KEY_PROFILE)}
          >
            Profile Information
          </Button>
          <Button
            size="large"
            variant={activeTab === TAB_KEY_MANAGE ? "contained" : ""}
            onClick={() => setActiveTab(TAB_KEY_MANAGE)}
          >
            Manage Access
          </Button>
          <Button
            size="large"
            variant={activeTab === TAB_KEY_ACTIVITY ? "contained" : ""}
            onClick={() => setActiveTab(TAB_KEY_ACTIVITY)}
          >
            Activity Log
          </Button>
        </Box>

        {activeTab === TAB_KEY_PROFILE && (
          <Box>
            <ProfileUpdate csv={csv} />
            <Divider sx={{ opacity: 1 }} />
            <ChangePassword
              loading={account.isLoading}
              handleSubmit={handleSubmitChangePassword}
            />
            <Divider sx={{ opacity: 1 }} />
            <ProfilePlan />
            <Divider sx={{ opacity: 1 }} />
            <ProfileFooter csv={csv} />
          </Box>
        )}

        {activeTab === TAB_KEY_MANAGE && (
          <Box>
            <DeputyList />
          </Box>
        )}

        {activeTab === TAB_KEY_ACTIVITY && (
          <Box>
            <ActivityLog />
          </Box>
        )}
      </Box>
    </PrivateLayout>
  );
};

export default Default;
