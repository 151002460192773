export const data = {
  benefits: [
    {
      imageName: require("../../Assets/Images/benefit-1.svg"),
      heading: "Make the process less stressful and confusing",
      description:
        "Most people forget key steps and do things wrong, such as forgetting to notify credit bureaus, cancel subscriptions quickly, and paying debt that doesn't need to be paid.",
    },
    {
      imageName: require("../../Assets/Images/benefit-2.svg"),
      heading: "Save time & get back to living faster",
      description:
        "It takes an average of 500 hours over 6 months to 2 years to wrap up the average estate. We help you get things done quickly, so you can go back to focusing on what matters.",
    },
    {
      imageName: require("../../Assets/Images/benefit-3.svg"),
      heading: "Avoid wasting thousands of dollars",
      description:
        "Simple mistakes can costs thousands of dollars in legal fees, taxes, and lost benefits. We help you preserve the financial legacy of your loved one.",
    },
  ],
  help: [
    { item: "Things to do immediately after a death" },
    { item: "Finding documents & accounts" },
    { item: "Financial accounts" },
    { item: "Subscriptions & utilities" },
    { item: "Bereavement & emotional support" },
    { item: "Digital accounts" },
    { item: "Funeral, memorial, burial & cremation" },
    { item: "Real estate & physical property" },
    { item: "Dependents & pets" },
  ],
  works: [
    {
      imageName: require("../../Assets/Images/step-1.png"),
      label: "Personalize your advice",
    },
    {
      imageName: require("../../Assets/Images/step-2.png"),
      label:
        "Get a comprehensive to-do list with all forms & simple instructions",
    },
    {
      imageName: require("../../Assets/Images/step-3.png"),
      label: "We help you get things done",
    },
  ],
  freeCell: {
    heading: "Peacefully is free",
    description:
      "That’s it. We believe everyone should have access to guidance during this difficult time.",
  },

  socials: [
    {
      platform: "facebook",
      url: "https://www.facebook.com/peacefullyio",
      title: "Check us out on facebook",
      iconImage: require("../../Assets/Images/facebook.png"),
    },
    {
      platform: "twitter",
      url: "https://twitter.com/peacefullyio",
      title: "Check us out on twitter",
      iconImage: require("../../Assets/Images/twitter.png"),
    },
    {
      platform: "linkedin",
      url: "https://www.linkedin.com/company/peacefully",
      title: "Check us out on linkedin",
      iconImage: require("../../Assets/Images/linkedin.png"),
    },
  ],
  settleAffairs: [
    {
      title: "Manage a Recent Loss",
      desc: "We’re here to help. We support with every step of the process - from getting required documentation, to freezing credit, to closing accounts, and more.",
      img: require("../../Assets/Images/img_need_left.png"),
      duration: "",
      price: "Plan Starting $7/month",
      btnTitle: "Settle Affairs",
      btnLink: "/auth/signup",
    },
    {
      title: "Plan for Myself",
      desc: "Discover comprehensive guidance on settling affairs, legal documents, and more. Create a legal estate plan and securely organize important documents, info, and accounts. We offer expert guidance every step of the way.",
      img: require("../../Assets/Images/img_need_right.png"),
      duration: "",
      price: "Plan Starting $7/month",
      btnTitle: "Start Planning",
      btnLink: "/auth/signup",
    },
  ],
  customerReviews: [
    {
      name: "Barbara S.",
      description:
        "I wouldn't have known what to do without them. They saved me so much time and energy during a hard time.",
      count: 5,
    },
    {
      name: "Joe M.",
      description:
        "Peacefully made it easy to get my siblings to help by giving them tasks to do. It really took the burden off of me.",
      count: 5,
    },
    {
      name: "Tiana R.",
      description:
        "Peacefully has an excellent team that is professional and always ready to help! They helped me figure out how to deal with a confusing bank process.",
      count: 5,
    },
    {
      name: "Sena O.",
      description:
        "Peacefully really has all of your needs already thought and planned out for you! They truly care about helping you in any way possible. If you need tips on estate management, senior living, end-of-life care, or any related topic, Peacefully has your back.",
      count: 5,
    },
    {
      name: "Libby F.",
      description:
        "It’s an emotional and confusing time when someone dies - all you want to know is you’re doing things right. Peacefully takes the guesswork out of closing accounts and all of the other odds and ends. Their team is kind and empathetic.",
      count: 5,
    },
    {
      name: "Elizabeth M.",
      description:
        "Peacefully has thought of everything for you when it comes to getting everything together in one place so that, when the day comes, your trusted person can handle things when you no longer can. I appreciate the level of detail when capturing the assets so that my family won't be stressed tracking things down once I'm gone.",
      count: 5,
    },
    {
      name: "Lily C.",
      description:
        "Peacefully helped with our estate planning which was so great because that was something I was very overwhelmed by. It was an easy process and completely alleviated my stress around the topic and task.",
      count: 5,
    },
    {
      name: "Barb.",
      description:
        "Such incredible value and comprehensive service - I logged in expecting to just be able to make a will, but I also got healthcare docs and was able to plan for my pets and digital estate.",
      count: 5,
    },
  ],
  features: [
    {
      name: "Protect Identity",
      description:
        "Posthumous identity theft is common, avoid costly legal fees  with simple precautions.",
      image: require("../../Assets/Images/new/footer/icon_identity.svg"),
    },
    {
      name: "Minimize Stress",
      description:
        "Ensure loved ones have what they need, simplify the process, and prevent conflict.",
      image: require("../../Assets/Images/new/footer/icon_family.svg"),
    },
    {
      name: "Save 500 Hours",
      description:
        "Save time and stress - it takes an average of 500 hours to wrap up the average estate.",
      image: require("../../Assets/Images/new/footer/icon_hour.svg"),
    },
  ],
  planning: [
    {
      name: "Legal and Healthcare",
      description:
        "Create a will, power of attorney, and get guidance on estate administration.",
      image: require("../../Assets/Images/new/planning/icon_legal_healthcare.svg"),
    },
    {
      name: "Financial Matters",
      description:
        "Identify all financial assets and distribute them, following all tax and legal rules.",
      image: require("../../Assets/Images/new/planning/icon_financial_matters.svg"),
    },
    {
      name: "Funeral Arrangements",
      description:
        "Coordinate the necessary arrangements for the funeral, including local cost estimates.",
      image: require("../../Assets/Images/new/planning/icon_funneral_arrangements.svg"),
    },
    {
      name: "Acquiring Benefits",
      description:
        "Collect applicable benefits, such as workers' compensation or veterans' benefits.",
      image: require("../../Assets/Images/new/planning/icon_acquiring_benefits.svg"),
    },
    {
      name: "Joint Collaboration",
      description:
        "Share your plan and set your permissions with a designated deputy.",
      image: require("../../Assets/Images/new/planning/icon_joint_collaboration.svg"),
    },
    {
      name: "Emotional Support",
      description:
        "Browse through therapy sessions, books, meditation, and more.",
      image: require("../../Assets/Images/new/planning/icon_emotional_support.svg"),
    },
  ],
  settling: [
    {
      name: "The First 48 Hours",
      description: "What to do immediately",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker.png"),
    },
    {
      name: "Most Essential Tasks",
      description:
        "Get the death certificate, Social Security benefit, and prevent identity theft",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker_7.png"),
    },
    {
      name: "Plan The Funeral",
      description:
        "Make arrangements for the body and funeral/memorial, and get local quotes",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker_4.png"),
    },
    {
      name: "Legal Documents and Process",
      description:
        "Find a lawyer, understand if you need to go through probate, and get an idea of next steps",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker_6.png"),
    },
    {
      name: "Financial Tasks",
      description:
        "Get guidance on requesting a taxpayer ID, estate bank account, asset distribution and filing final taxes",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker_1.png"),
    },
    {
      name: "Saving Money and Closing Accounts",
      description:
        "Prevent unnecessary charges by modifying accounts, appointments, and subscriptions",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker_3.png"),
    },
    {
      name: "Receiving Benefits",
      description:
        "Collect relevant benefits, from workers compensation to life insurance to veterans benefits",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker_5.png"),
    },
    {
      name: "Emotional Support",
      description:
        "Get support through therapy, support or spiritual groups, reflection exercises, meditation, grief books, or legacy projects",
      image: require("../../Assets/Images/new/settling/ezgif.com-gif-maker_2.png"),
    },
  ],
  newWorks: [
    {
      title: "Step-by-step guidance",
      description:
        "Straight-forward advice on everything from which documents to complete to how to find emotional peace.",
    },
    {
      title: "Secure Planning Vault",
      description:
        "Comprehensive organization with industry-leading security to safeguard your personal information.",
    },
    {
      title: "Legal Documents of your state",
      description:
        "Get a Will, Power and Healthcare Attorney, Living Will, and Advance Healthcare Directive customized.",
    },
    {
      title: "Automatic Form Filling",
      description:
        "We will fill and submitted required forms - from locking down credits scores to preventing identity theft.",
    },
    {
      title: "Expert Guidance",
      description:
        "We match you to a professional based on your needs, our experts will be there every step of the way.",
    },
  ],
};
