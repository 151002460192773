import { createSlice } from "@reduxjs/toolkit";
import Amplify from "aws-amplify";
import { encryptStorage } from "../../Components/Helpers/commonMethods";

const initialState = {
  isLoading: false,
  isAuthenticated: encryptStorage.getItem("token") ? true : false,
  user: encryptStorage.getItem("user") ? JSON.parse(encryptStorage.getItem("user")) : null,
  token: encryptStorage.getItem("token"),
  operateEmail: encryptStorage.getItem("operateEmail"),
  sidebarToggle: true,
  sessionInfo: {},
  errorMessage: null,
  referer: null,
  checkAccessRequest: false,
};

const {
  actions: {
    cognitoRequest,
    loginStart,
    loginSuccess,
    loginFailure,
    getLogout,
    sidebarToggle,
    getSessionInfoStart,
    getSessionInfoSuccess,
    getSessionInfoFailure,
    updateUserProfile,
    setReferer,
    setCheckAccessRequest,
    setOperatingEmail
  },
  reducer,
} = createSlice({
  name: "account",
  initialState,
  reducers: {
    cognitoRequest: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    loginStart: (state) => ({
      ...state,
      isLoading: true,
      errorMessage: null,
      checkAccessRequest: true,
    }),
    loginSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isAuthenticated: true,
      token: action.payload.access_token,
      user: action.payload,
      errorMessage: null,
    }),
    loginFailure: (state, action) => ({
      ...state,
      isLoading: false,
      token: null,
      user: null,
      isAuthenticated: false,
      errorMessage: action.payload,
    }),
    getLogout: (state) => {
      Amplify.Auth.signOut({ global: true });
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("closingTime");

      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        isLoading: false,
        errorMessage: null,
      };
    },
    sidebarToggle: (state, action) => ({
      ...state,
      sidebarToggle: action.payload,
    }),
    getSessionInfoStart: (state) => ({
      ...state,
      isLoading: true,
    }),
    getSessionInfoSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      sessionInfo: action.payload,
    }),
    updateUserProfile: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    getSessionInfoFailure: (state) => ({
      ...state,
      isLoading: false,
      sessionInfo: {},
    }),
    setReferer: (state, action) => ({
      ...state,
      referer: action.payload,
    }),
    setCheckAccessRequest: (state, action) => ({
      ...state,
      checkAccessRequest: action.payload
    }),
    setOperatingEmail: (state, action) => {
      const email = action.payload;
      encryptStorage.setItem("operateEmail", email);
      return {
        ...state,
        operateEmail: action.payload
      }
    }
  },
});

export default reducer;
export {
  cognitoRequest,
  loginStart,
  loginSuccess,
  loginFailure,
  getLogout,
  sidebarToggle,
  getSessionInfoStart,
  getSessionInfoSuccess,
  getSessionInfoFailure,
  updateUserProfile,
  setReferer,
  setCheckAccessRequest,
  setOperatingEmail
};
