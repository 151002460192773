import React from "react";
import { Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const DatePicker = React.memo(
  ({
    parseError,
    normalInput = false,
    type,
    required,
    name,
    label,
    sx,
    ...rest
  }) => {
    return (
      <Controller
        name={name}
        render={({
          field: { onChange, value },
          fieldState: { error, invalid }
        }) => {
          return (
            <>
              <DateTimePicker
                {...rest}
                label={label}
                value={value}
                onChange={(event) => {
                  onChange(event);
                }}
                sx={
                  invalid
                    ? {
                        ...sx,
                        "& fieldset": {
                          borderColor: "#d32f2f !important"
                        },
                        "& label": {
                          color: "#d32f2f"
                        }
                      }
                    : { ...sx }
                }
              />
              {invalid && (
                <Typography variant="body2" color={"error"}>
                  {error.message}
                </Typography>
              )}
            </>
          );
        }}
      />
    );
  }
);

export default DatePicker;
