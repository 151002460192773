import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRedirecting: false,
  isFrameLoading: false,
  isShowIframe: false,
  counting: 0,
  activeMenu: null
};

const { reducer, actions: { setRedirecting, setIsFrameLoading, setShowIframe, increaseBoardCounting, setActiveMenu } } = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setRedirecting: (state, action) => ({
      ...state,
      isRedirecting: action.payload
    }),
    increaseBoardCounting: (state) => ({
      ...state,
      counting: state.counting + 1,
    }),
    setActiveMenu: (state, action) => ({
      ...state,
      activeMenu: action.payload
    }),
    setIsFrameLoading: (state, action) => ({
      ...state,
      isFrameLoading: action.payload
    }),
    setShowIframe: (state, action) => ({
      ...state,
      isShowIframe: action.payload
    })
  }
});

export default reducer;

export { setRedirecting, setIsFrameLoading, setShowIframe, increaseBoardCounting, setActiveMenu };
