import { Container, Typography, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SignUpForm from "../../../Components/SignUpForm";
import imgTrustPilot from "../../../Assets/Images/img_trustpilot.png";
import { SIGN_UP_METHOD_NORMAL } from "../../../Assets/Consts";

export default function HeroGetStarted() {
  const isPromoUser = "";
  const account = useSelector((state) => state.account);
  const navigate = useNavigate();

  useEffect(() => {
    if (account?.isAuthenticated) navigate("/auth/progress");
  }, [account, navigate]);

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
        p: {
          xs: "60px 0",
          sm: "70px 0",
          md: "120px 0",
          lg: "60px 0 60px 0",
        },
        backgroundImage: `url(${require("../../../Assets/Images/bg_getstarted_hero.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Box
            sx={{
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "22px",
                  sm: "36px",
                  lg: "50px",
                },
                lineHeight: {
                  xs: "30px",
                  sm: "40px",
                  md: "49px",
                  lg: "68px",
                },
                mb: {
                  xs: "10px",
                  sm: "20px",
                  md: "30px",
                },
              }}
              color="grey.900"
            >
              <span style={{ color: "#4b6eae" }}>Peace of mind</span> for <br />{" "}
              you and your family
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "18px",
                  lg: "22px",
                },
                mb: {
                  xs: "20px",
                  sm: "30px",
                  md: "40px",
                },
                fontWeight: "400",
                lineHeight: {
                  xs: "30px",
                  sm: "35px",
                  lg: "40px",
                },
                maxWidth: "600px",
              }}
              color="grey.900"
            >
              A caring solution designed to help you plan for the future and
              expertly settle affairs after the loss of a loved one.
            </Typography>

            <Box sx={{ mt: "30px" }}>
              <a
                target="_blank"
                href="https://www.trustpilot.com/review/peacefully.com"
                rel="noreferrer"
              >
                <Box component="img" src={imgTrustPilot} alt="Trustpilot" />
              </a>
            </Box>
          </Box>

          <Box>
            <SignUpForm
              isPromoUser={isPromoUser}
              method={SIGN_UP_METHOD_NORMAL}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
