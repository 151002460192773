import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PrivateLayout from "../../Layouts/PrivateLayout";
import IconMissCall from "../../Assets/Images/icon_miss_call.svg";
import AvatarAccount from "../../Assets/Images/account-phone.jpg";
import {
  CONTACT_EMAIL,
  CONTACT_PHONE,
  CONTACT_SCHEDULE_LINK,
} from "../../Assets/Consts/contact";

const Default = () => {
  const account = useSelector((state) => state.account);

  const handleOpenChat = () => {
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      email: account?.user?.email,
      name: `${account?.user.first_name} ${account?.user.last_name}`,
    };
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      email: account?.user?.email,
      name: `${account?.user.first_name} ${account?.user.last_name}`,
    });

    window.Intercom("show");
  };

  return (
    <PrivateLayout>
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          p: {
            xs: "15px",
            sm: "40px 20px",
            md: "20px",
            lg: "38px",
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: {
              xs: "18px",
              sm: "28px",
            },
            mb: {
              xs: "15px",
              sm: "20px",
            },
          }}
        >
          Get Help
        </Typography>
        <Box
          sx={{
            borderRadius: "20px",
            boxShadow: `0px 4px 15px 0px rgba(0, 0, 0, 0.15)`,
            p: "60px 20px",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "1fr 4.5fr",
              },
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                width={120}
                height={120}
                sx={{ borderRadius: "100px", overflow: "hidden" }}
              >
                <Box
                  component="img"
                  sx={{ width: "100%" }}
                  src={AvatarAccount}
                ></Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  md: "1fr",
                  lg: "440fr 230fr",
                },
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "left",
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "22px",
                    },
                    mb: "10px",
                  }}
                >
                  We are so glad you're here.
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "18px",
                    },
                  }}
                >
                  Peacefully's team of Estate Planning and Settling experts are ready to provide guidance and assistance for your questions and concerns.
                </Typography>
              </Box>
              {false && (
                <Box
                  sx={{
                    borderRadius: "5px",
                    background: "#e6edf9",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Box component="img" src={IconMissCall} sx={{ mr: "10px" }} />

                  <Typography
                    variant="h7"
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      color: "#737373",
                    }}
                  >
                    Call Support available only for Advanced and Concierge Plan
                    Members,{" "}
                    <Typography
                      color="primary.light"
                      sx={{ cursor: "pointer", fontSize: "13px" }}
                      component={Link}
                      to="/plan"
                    >
                      upgrade here.
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {true && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  md: "1fr 4.5fr",
                },
                alignItems: "center",
                pt: "20px",
                mt: "20px",
                borderTop: "1px solid #F6F6F6",
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "22px",
                    },
                    mb: "10px",
                    textAlign: {
                      xs: "left",
                      md: "center",
                    },
                  }}
                >
                  Contact
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "1fr 1fr",
                    md: "1fr 1fr 1fr",
                    lg: "1fr 1fr 1fr 1fr",
                  },
                  gap: "20px",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    color="grey.600"
                    sx={{
                      fontSize: {
                        xs: "18px",
                      },
                      mb: "10px",
                    }}
                  >
                    Phone
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: {
                        xs: "18px",
                      },
                    }}
                  >
                    {CONTACT_PHONE}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    color="grey.600"
                    sx={{
                      fontSize: {
                        xs: "18px",
                      },
                      mb: "10px",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: {
                        xs: "18px",
                      },
                    }}
                  >
                    {CONTACT_EMAIL}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    color="grey.600"
                    sx={{
                      fontSize: {
                        xs: "18px",
                      },
                      mb: "10px",
                    }}
                  >
                    Schedule a Call
                  </Typography>
                  <a
                    rel="noreferrer"
                    className="help_content_wrapper_con_item-link"
                    href={CONTACT_SCHEDULE_LINK}
                    target="_blank"
                    style={{ color: "#22427d", fontWeight: "bolder" }}
                  >
                    Calendly
                  </a>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleOpenChat}
                  >
                    Open Chat
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </PrivateLayout>
  );
};

export default Default;
