import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

import { userUpdateUserProfile } from "../../Services/User";
import {
  setOperatingEmail,
  updateUserProfile
} from "../../Redux-Saga/Redux/account";
import PrivateLayout from "../../Layouts/PrivateLayout";
import { WelcomeModal, UpgradePlanModal } from "../../Components/modals";
import { encryptStorage } from "../../Components/Helpers/commonMethods";

import OwnerBoard from "./OwnerBoard";
import MainBoard from "./MainBoard";
import { setShowIframe } from "../../Redux-Saga/Redux/ui";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { sessionInfo, token, user, operateEmail } = useSelector(
    (state) => state.account
  );

  const { currentUser = {}, assignUserList, currentPlan } = sessionInfo;
  const {
    qualtrics_user_id: qualtricsUserId,
    survey_id: userSurveyId,
    email,
    id: userId,
    isLoading: isLoadingUser,
  } = currentUser;

  const [welcomeModal, setWelcomeModal] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState(false);

  const selectedOwner = useMemo(() => {
    return assignUserList?.find((itm) => itm.email === operateEmail);
  }, [assignUserList, operateEmail]);

  const handleWelcomeClose = () => {
    setWelcomeModal(false);
    userUpdateUserProfile(token, {
      onboard: true
    }).then(function (res) {
      const prepareData = {
        ...user,
        onboard: true
      };
      dispatch(updateUserProfile(prepareData));
      encryptStorage.setItem("user", prepareData);
    });
  };

  useEffect(() => {
    if (!user?.onboard) {
      setWelcomeModal(false);
    }

    return () => {
      dispatch(setShowIframe(false));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentPlan?.name === "Silver") {
      if (!sessionStorage.getItem("loggined")) {
        setUpgradePlan(true);
        sessionStorage.setItem("loggined", true);
      }
    }
  }, [currentPlan]);

  useEffect(() => {
    if (!userSurveyId && !selectedOwner && assignUserList?.length > 0) {
      dispatch(setOperatingEmail(assignUserList[0].email));
    }
  }, [userSurveyId, assignUserList, selectedOwner, dispatch]);

  const handleCloseUpgrade = () => {
    setUpgradePlan(false);
  };

  if (isLoadingUser || !email || !userId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!qualtricsUserId) {
    return (
      <PrivateLayout>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      </PrivateLayout>
    );
  }

  return (
    <PrivateLayout>
      <Box
        flex={1}
        bgcolor="#fafafa"
        display="flex"
        flexDirection="column"
        position="relative"
        width="100%"
        height="100%"
      >
        {!!selectedOwner ? (
          <OwnerBoard owner={selectedOwner} />
        ) : (
          <MainBoard />
        )}
      </Box>

      <WelcomeModal open={welcomeModal} onClose={handleWelcomeClose} />
      <UpgradePlanModal open={upgradePlan} onClose={handleCloseUpgrade} />
    </PrivateLayout>
  );
};

export default Dashboard;
