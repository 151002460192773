import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardImg from "../../../Assets/Images/dashboard.png";
import PublicLayout from "../../../Layouts/PublicLayout";
import FooterFixNav from "../Component/FooterFixNav";
import { deleteQualtricsUser } from "../../../Services/User";
import SignUpForm from "../../../Components/SignUpForm";
import { SIGN_UP_METHOD_NORMAL } from "../../../Assets/Consts";

const Signup = () => {
  const account = useSelector((state) => state.account);
  const location = useLocation();

  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const isPromoUser = params.get("promo") === "1";

  const initialValues = {
    firstName: params.get('firstName') || '',
    lastName: params.get('lastName') || '',
    email: params.get('email') ? decodeURIComponent(params.get('email')) : '',
  };

  useEffect(() => {
    if (account?.isAuthenticated) navigate("/auth/progress");
  }, [account, navigate]);

  useEffect(() => {
    return () => {
      const qualtricsId = localStorage.getItem("qualtricsId");
      if (qualtricsId) deleteQualtricsUser(qualtricsId);
    };
  }, []);

  return (
    <PublicLayout>
      <Box flex={1} display="flex">
        <Box
          width={500}
          position="relative"
          py={6}
          px={6}
          flexDirection="column"
          sx={{ display: { xs: "none", lg: "flex" } }}
        >
          <Typography
            variant="h4"
            color="primary.light"
            align="center"
            fontWeight="600"
            sx={{ lineHeight: "40px" }}
          >
            Store and share critical information through our secure planning
            vault with industry-leading security.
          </Typography>

          <Box flex={1} display="flex" justifyContent="center" alignItems="center">
            <img src={DashboardImg} width="100%" alt="Share Information" />
          </Box>

          <FooterFixNav />
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          sx={{ bgcolor: { xs: "#fff", sm: "#f2f7f7" } }}
        >
          <Box
            flex={1}
            display="flex"
            py={6}
            justifyContent="center"
            alignItems="center"
          >
            <SignUpForm
              isPromoUser={isPromoUser}
              method={SIGN_UP_METHOD_NORMAL}
              hubspotId={params.get('hId')}
              qualtricsId={params.get('qId')}
              initialData={initialValues}
            />
          </Box>

          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <FooterFixNav />
          </Box>
        </Box>
      </Box>
    </PublicLayout>
  );
};

export default Signup;
