import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const CardForm = ({ loading }) => {
  const [error, setError] = useState("");

  const handleCard = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError("");
    }
  };

  return (
    <>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <Box>
            <Typography color="grey.400" sx={{ mb: 1, px: 1 }}>
              Cardholders Name
            </Typography>

            <TextField
              fullWidth
              name="accountHolder"
              placeholder="First Last Name"
              sx={{
                "& input": { fontSize: 16, px: 3, py: 2.3125 },
                "& input::placeholder": { color: "#c0c0c0", opacity: 1 },
                "& fieldset": { border: "solid 1px #eaeaea !important" },
                "& .MuiInputBase-root": {
                  borderRadius: 8,
                  height: 60,
                  border: "none !important",
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Typography color="grey.400" sx={{ mb: 1, px: 1 }}>
              Card number
            </Typography>
            <CardNumberElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    "::placeholder": { color: "#c0c0c0" },
                  },
                },
              }}
              onChange={handleCard}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography color="grey.400" sx={{ mb: 1, px: 1 }}>
              Expiration date
            </Typography>
            <CardExpiryElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    lineHeight: "22px",
                    "::placeholder": { color: "#c0c0c0" },
                  },
                },
              }}
              onChange={handleCard}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography color="grey.400" sx={{ mb: 1, px: 1 }}>
              CVC
            </Typography>
            <CardCvcElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    lineHeight: "22px",
                    "::placeholder": { color: "#c0c0c0" },
                  },
                },
              }}
              onChange={handleCard}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={2}>
            <Typography color="grey.400" sx={{ mb: 1, px: 1 }}>
              Promo Code
            </Typography>
            <TextField
              fullWidth
              name="promo"
              placeholder="PROMO CODE"
              sx={{
                "& input": { fontSize: 16, px: 3, py: 2.3125 },
                "& input::placeholder": { color: "#c0c0c0", opacity: 1 },
                "& fieldset": { border: "solid 1px #eaeaea !important" },
                "& .MuiInputBase-root": {
                  borderRadius: 8,
                  height: 60,
                  border: "none !important",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {error && (
        <Box mb={2}>
          <Typography color="error" variant="body2" sx={{ px: 1 }}>
            {error}
          </Typography>
        </Box>
      )}

      <Box display="flex" alignItems="center" justifyContent="center">
        <LoadingButton
          loading={loading}
          type="submit"
          sx={{ px: 8 }}
          size="large"
          color="primary"
          variant="contained"
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
};

export default CardForm;
