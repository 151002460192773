import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Input = React.memo(
  ({
    parseError,
    normalInput = false,
    type,
    required,
    name,
    label,
    sx,
    ...rest
  }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <Controller
        name={name}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error }
        }) => (
          <TextField
            {...rest}
            name={name}
            label={label}
            value={value || ""}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            type={type === "password" && showPassword ? "text" : type}
            error={invalid}
            InputProps={{
              endAdornment: type === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev) => !prev)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={
              normalInput
                ? {}
                : {
                    overflow: "hidden",
                    "& .MuiInputBase-root": {
                      borderRadius: 8,
                      bgcolor: "#fff",
                      border: "solid 1px #EAEAEA"
                    },
                    "& .MuiInputBase-root:hover": {
                      borderRadius: 8,
                      bgcolor: "#fff"
                    },
                    "& .MuiInputBase-root input": {
                      borderRadius: 8
                    },
                    "& .MuiInputBase-root:before": {
                      borderBottom: "none !important"
                    },
                    "& .MuiInputBase-root:after": {
                      borderBottom: "none !important"
                    },
                    "& .MuiInputBase-adornedEnd": { paddingRight: 2 },
                    "& input": { paddingLeft: 2.5 },
                    "& label": {
                      paddingLeft: "8px",
                      color: "#A3A3AD !important"
                    },
                    ...sx
                  }
            }
            helperText={
              <Typography variant="body2">
                {error
                  ? typeof parseError === "function"
                    ? parseError(error)
                    : error.message
                  : rest.helperText}
              </Typography>
            }
            FormHelperTextProps={{ variant: "standard", component: "div" }}
          />
        )}
      />
    );
  }
);

export default Input;
