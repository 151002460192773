import React from "react";
import PublicLayout from "../../Layouts/PublicLayout";
import "./style.scss";

const CopyRight = () => {
  return (
    <PublicLayout>
      <div className="copy_right">
        Any other use of this website or any text, pictures, or use of anything
        found on this website without the express consent of Peacefully.com is
        prohibited. Please see <a href="http://copyright.gov/">copyright.gov</a>{" "}
        for more information.
      </div>
    </PublicLayout>
  );
};

export default CopyRight;
