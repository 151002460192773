import React, { useState } from "react";

import callActionImg from "../../../Assets/Images/new/call-action-bg.png";

import "./callAction_old.scss";
import RequestModal from "../../Home/modal/RequestModal";
import { Button, Container, Grid } from "@mui/material";

const Default = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <section
      className="call_to_Action"
      style={{
        backgroundImage: `url(${callActionImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ zIndex: 999 }} className="text-center">
            <div className="call_to_Action-header">
              For Employers & Insurance
            </div>

            <div className="call_to_Action-desc">
              Help support your employees when times are tough.
            </div>

            <div className="call_to_Action-footer">
              <Button
                variant="contained"
                size="large"
                component="a"
                href="https://peacefully.com/employers/"
              >
                Learn More
              </Button>

              <Button
                variant="contained"
                size="large"
                component="a"
                href="https://peacefully.com/contact-us/"
              >
                Request a Demo
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>

      {dialogOpen && <RequestModal dialogClose={() => setDialogOpen(false)} />}
    </section>
  );
};

export default Default;
