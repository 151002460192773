import React, { useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CardCheckoutForm from "../Component/CardCheckoutForm";
import { getSessionInfoStart } from "../../../Redux-Saga/Redux/account";

import { ReactComponent as PoweredStripeIcon } from "../../../Assets/Images/powerd-by-stripe.svg";
import ImgCreditCard from "../../../Assets/Images/img_credit_card.svg";
import IconPaymentSuccess from "../../../Assets/Images/icon_payment_success.svg";
import { formatDate } from "../../../Services/Utils/helper";

const PlanDialog = ({ handleClose, choosePlan }) => {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState();

  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const successMsg = useMemo(() => {
    if (!currentPeriodEnd) return "";
    if (currentPeriodEnd === "lifetime") return "You purchased lifetime access successfully. You may now continue using peacefully.";
    return `Your subscription plan was purchased successfully and will end ${formatDate(currentPeriodEnd * 1000)}. You may now continue using Peacefully.`;
  }, [currentPeriodEnd]);

  const handleSubmit = (result) => {
    setShowSuccess(true);
    setCurrentPeriodEnd(result.current_period_end);
    dispatch(getSessionInfoStart(account.token));
  };

  return (
    <Dialog
      maxWidth="xs"
      className="plan"
      open={true}
      PaperProps={{ sx: { borderRadius: 8, overflow: "hidden" } }}
      onClose={handleClose}
    >
      <Box overflow="auto">
        <Box p={2} display="flex" justifyContent="flex-end">
          <CloseIcon
            sx={{ fontSize: 28, cursor: "pointer", color: "grey.700" }}
            onClick={() => handleClose()}
          />
        </Box>

        {showSuccess ? (
          <Box px={2} pb={5}>
            <Box display="flex" justifyContent="center" mb={2}>
              <img src={IconPaymentSuccess} alt="success" />
            </Box>

            <Typography
              variant="h2"
              align="center"
              fontWeight="600"
              color="primary.dark"
              sx={{ mb: 2 }}
            >
              Payment Successful
            </Typography>

            <Typography
              align="center"
              color="grey.700"
              sx={{ px: { xs: 0, sm: 1 } }}
            >
              {successMsg}
            </Typography>

            <Box
              pt={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ px: 8 }}
                onClick={() => navigate("/dashboard")}
              >
                Continue
              </Button>
            </Box>
          </Box>
        ) : (
          <Box px={2} pb={5}>
            <Typography
              align="center"
              variant="h2"
              fontWeight="600"
              color="primary.dark"
              sx={{ mb: 2 }}
            >
              Payment Details
            </Typography>

            <Typography variant="h5" align="center" color="" sx={{ mb: 2 }}>
              Total Bill: ${choosePlan.total}
            </Typography>

            <Box display="flex" justifyContent="center" mb={2.5}>
              <img src={ImgCreditCard} alt="credit card" />
            </Box>

            <Elements stripe={stripePromise}>
              <CardCheckoutForm
                planData={choosePlan}
                token={account.token}
                callBackHandleSubmit={handleSubmit}
              />
            </Elements>

            <Box display="flex" justifyContent="center" mt={2.5}>
              <PoweredStripeIcon width={150} />
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default PlanDialog;
