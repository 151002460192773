import { Button, Dialog, Grid, Box, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconCheckMark from "../../../Assets/Images/icon_check_mark.svg";

const SuccessModal = ({ title, description, open, docName, onClose, onOk }) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 4 } }}
    >
      <Box p={3}>
        <Box position="absolute" top="20px" right="20px">
          <CloseIcon
            sx={{ fontSize: 32, cursor: "pointer" }}
            color="#000000"
            onClick={() => onClose()}
          />
        </Box>

        <Box display="flex" justifyContent="center" my={2.5}>
          <img
            width={100}
            height={100}
            src={IconCheckMark}
            alt="Remove Doc Success"
          />
        </Box>
        <Box mb={4}>
          <Typography
            variant="h2"
            align="center"
            color="primary.dark"
            fontWeight={600}
          >
            {title}
          </Typography>
        </Box>
        <Box px={2} mb={14}>
          <Typography align="center" color="grey.700">
            {description}
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={8}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={onOk}
            >
              Okay
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default SuccessModal;
