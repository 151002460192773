import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "@mui/material";
import iconLegalEstate from "../../../Assets/Images/icon_legal_estate.svg";
import iconPet from "../../../Assets/Images/icon_pet.svg";
import iconFinancial from "../../../Assets/Images/icon_financial.svg";
import iconFuneral from "../../../Assets/Images/icon_funeral.svg";
import iconDigital from "../../../Assets/Images/icon_digital.svg";
import iconSetting from "../../../Assets/Images/icon_setting.svg";
import "./style.scss";

export default function SectionService() {
  return (
    <div className="services">
      <Container>
        <div className="services-sub-title">Services</div>
        <div className="services-title">
          How Peacefully provides support when it matters the most
        </div>
        <div className="services-desc">
          We understand the process inside and out, and we're committed to
          making sure you and your family receive the compressive care and
          support you deserve.
        </div>
        <div className="services_container">
          <div className="services_container-item">
            <div className="services_container-item-front">
              <div className="services_container-item-front-card">
                <img
                  className="services_container-item-front-card-img"
                  src={iconLegalEstate}
                  alt="Estate"
                />
                <p className="services_container-item-front-card-desc">
                  Legal Estate & Healthcare Plan
                </p>
              </div>
            </div>
            <div className="services_container-item-back">
              <div className="services_container-item-back-card">
                <h3 className="services_container-item-back-card-title">
                  Legal Estate & Healthcare Plan
                </h3>
                <ul className="services_container-item-back-card-desc">
                  <li>
                    Create will, trust, power of attorney and guardianship
                    documents.
                  </li>
                  <li>
                    Document your healthcare wishes with an advance directive
                    and medical power of attorney.
                  </li>
                  <li>
                    Upload important healthcare information so your loved ones
                    have them in case of an emergency.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="services_container-item">
            <div className="services_container-item-front">
              <div className="services_container-item-front-card">
                <img
                  className="services_container-item-front-card-img"
                  src={iconPet}
                  alt="Pet"
                />
                <p className="services_container-item-front-card-desc">
                  Pets & Children
                </p>
              </div>
            </div>
            <div className="services_container-item-back">
              <div className="services_container-item-back-card">
                <h3 className="services_container-item-back-card-title">
                  Pets & Children
                </h3>
                <ul className="services_container-item-back-card-desc">
                  <li>
                    Share important information on how to care for children,
                    pets, and adult dependents.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="services_container-item">
            <div className="services_container-item-front">
              <div className="services_container-item-front-card">
                <img
                  className="services_container-item-front-card-img"
                  src={iconFinancial}
                  alt="Financial"
                />
                <p className="services_container-item-front-card-desc">
                  Financial & Property
                </p>
              </div>
            </div>
            <div className="services_container-item-back">
              <div className="services_container-item-back-card">
                <h3 className="services_container-item-back-card-title">
                  Financial & Property
                </h3>
                <ul className="services_container-item-back-card-desc">
                  <li>
                    Share important information on real estate, vehicles,
                    valuables, storage facilities, and business ownership.Make
                    sure your heirs can find your investment, retirement, and
                    bank accounts, credit cards, insurance, and tax documents.
                  </li>
                  <li>Make sure your family can access benefits.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="services_container-item">
            <div className="services_container-item-front">
              <div className="services_container-item-front-card">
                <img
                  className="services_container-item-front-card-img"
                  src={iconFuneral}
                  alt="Funeral"
                />
                <p className="services_container-item-front-card-desc">
                  Funeral & Legacy Planning
                </p>
              </div>
            </div>
            <div className="services_container-item-back">
              <div className="services_container-item-back-card">
                <h3 className="services_container-item-back-card-title">
                  Funeral & Legacy Planning
                </h3>
                <ul className="services_container-item-back-card-desc">
                  <li>Plan a funeral, burial or cremation, and get quotes.</li>
                  <li>
                    Leave something behind for loved ones to remember you by.
                  </li>
                  <li>Get professional or spiritual support.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="services_container-item">
            <div className="services_container-item-front">
              <div className="services_container-item-front-card">
                <img
                  className="services_container-item-front-card-img"
                  src={iconDigital}
                  alt="Digital"
                />
                <p className="services_container-item-front-card-desc">
                  Digital Afterlife & Subscriptions
                </p>
              </div>
            </div>
            <div className="services_container-item-back">
              <div className="services_container-item-back-card">
                <h3 className="services_container-item-back-card-title">
                  Digital Afterlife & Subscriptions
                </h3>
                <ul className="services_container-item-back-card-desc">
                  <li>
                    Decide how your online accounts, memberships, photos, and
                    devices will be handled.
                  </li>
                  <li>
                    Make sure your family can handle your utilities, cable,
                    phone bills, and more.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="services_container-item">
            <div className="services_container-item-front">
              <div className="services_container-item-front-card">
                <img
                  className="services_container-item-front-card-img"
                  src={iconSetting}
                  alt="Setting"
                />
                <p className="services_container-item-front-card-desc">
                  Settling Affairs
                </p>
              </div>
            </div>
            <div className="services_container-item-back">
              <div className="services_container-item-back-card">
                <h3 className="services_container-item-back-card-title">
                  Settling Affairs
                </h3>
                <ul className="services_container-item-back-card-desc">
                  <li>What to do in the first 48 hours</li>
                  <li>
                    Get the death certificate, social security, and prevent
                    identity theft
                  </li>
                  <li>Plan a funeral</li>
                  <li>Legal documents and processes </li>
                  <li>Asset search and distribution</li>
                  <li>Close accounts</li>
                  <li> Get benefits </li>
                  <li>Find emotional support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="services-footer">
          <Button
            variant="outlined"
            size="large"
            component={Link}
            sx={{ px: 8 }}
            to={"/auth/signup"}
          >
            Learn More
          </Button>
        </div>
      </Container>
    </div>
  );
}
