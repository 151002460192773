import { Button, Dialog, Box, Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconDelete from "../../../Assets/Images/icon_delete.svg";

const ConfirmDeleteModal = ({ open, onClose, onOk }) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 4 } }}
    >
      <Box p={3}>
        <Box position="absolute" top="20px" right="20px">
          <CloseIcon
            sx={{ fontSize: 32, cursor: "pointer" }}
            color="#000000"
            onClick={() => onClose()}
          />
        </Box>

        <Box display="flex" justifyContent="center" my={2.5}>
          <img width={100} height={100} src={IconDelete} alt="Remove Doc" />
        </Box>

        <Box mb={4}>
          <Typography
            variant="h2"
            align="center"
            color="primary.dark"
            fontWeight={600}
          >
            Are you sure?
          </Typography>
        </Box>

        <Box px={2} mb={14}>
          <Typography align="center" color="grey.700">
            Do you really want to delete this document? This process cannot be
            undone.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={onOk}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
