import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const FooterFixNav = () => {
  return (
    <Box
      px={2}
      py={2.5}
      sx={{ bottom: 0, left: 0, right: 0 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Stack direction="row" spacing={4}>
        <Typography color="#a3a3ad" variant="body2">©Peacefully Inc.</Typography>

        <Typography
          color="#a3a3ad"
          variant="body2"
          component="a"
          target="_blank"
          className="link"
          rel="noreferrer"
          href="https://www.peacefully.com/terms-services"
        >
          Terms of Use
        </Typography>

        <Typography
          color="#a3a3ad"
          variant="body2"
          component="a"
          target="_blank"
          className="link"
          rel="noreferrer"
          href="https://www.peacefully.com/privacy-policy"
        >
          Privacy Policy
        </Typography>
      </Stack>
    </Box>
  );
};

export default FooterFixNav;
