import React from "react";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconSwitch from "../../../Assets/Images/icon_switch.svg";

const SwitchAccountModal = ({ open, onClose, onOk }) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 4 } }}
    >
      <Box p={3}>
        <Box position="absolute" top="20px" right="20px">
          <CloseIcon
            sx={{ fontSize: 32, cursor: 'pointer' }}
            color="#000000"
            onClick={() => onClose()}
          />
        </Box>

        <Box display="flex" justifyContent="center" my={2.5}>
          <img width={100} height={100} src={IconSwitch} alt="Account Switch" />
        </Box>

        <Box mb={4}>
          <Typography variant="h2" align="center" color="primary" fontWeight={600}>
            Are you sure you want to switch accounts?
          </Typography>
        </Box>

        <Box px={8} mb={5}>
          <Typography align="center">
            Upon switching accounts, you'll be redirected to another dashboard.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => onClose()}
            >
              Don't Switch
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={onOk}
            >
              Switch
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default SwitchAccountModal;
