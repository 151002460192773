import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StressFreeImg from "../../../Assets/Images/stress-free.png";

import "./StressFree_old.scss";

const StressFree = ({ signupUrl }) => {
  return (
    <section className="process_free">
      <Container>
        <Grid container className="align-items-center">
          <Grid item xs={12} sm={6}>
            <div className="process_free-wrapper px-5 ps-md-0">
              <div className="process_free-wrapper-title">
                Making the <br /> process stress-free
              </div>

              <div className="process_free-wrapper-description">
                We’re all about making estate planning and administration
                hassle-free. We understand the process inside and out, and we're
                committed to making sure you and your family receive the
                comprehensive care and support you deserve, every step of the
                way.
              </div>

              <Box>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="fs-5 text-black fw-semibold">
                      Step by Step Guidance
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    Straight-forward advice on everything you need to know from
                    which documents to complete to how to find emotional peace.
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="fs-5 text-black fw-semibold">
                      Secure Planning Vault
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    Comprehensive organization with industry-leading security to
                    safeguard your personal information.
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="fs-5 text-black fw-semibold">
                      Legal Documents of Your State
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    Get a Will, Power and Healthcare Attorney, Living Will, and
                    Advance Healthcare Directive customized.
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="fs-5 text-black fw-semibold">
                      Automatic Form Filling
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    We will fill and submitted required forms - from locking
                    down credits scores to preventing identity theft.
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="fs-5 text-black fw-semibold">
                      Expert Guidance
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    We match you to a professional based on your needs, our
                    experts will be there every step of the way.
                  </AccordionDetails>
                </Accordion>
              </Box>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box px={5} py={5}>
              <img src={StressFreeImg} alt="Make Process" style={{ width: '100%' }} />
            </Box>
          </Grid>

          <Grid item xs={12} className="process_free-footer">
            <Box py={4} display="flex" flexDirection="column">
              <Button
                variant="contained"
                size="large"
                sx={{ mb: 2 }}
                component="a"
                href={signupUrl}
              >
                Get Started for Free
              </Button>

              <Typography color="grey.600">
                No credit card information required.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default StressFree;
