import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { userUpdateAvatar } from "../../../Services/User";
import { updateUserProfile } from "../../../Redux-Saga/Redux/account";
import { encryptStorage } from "../../Helpers/commonMethods";
import initials from "initials";
import FileUploader from "../../FileUploader";
import "./style.scss";

const PhotoUploadModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const handleFile = (evt) => {
    setFileList(evt.target.files);
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileList[0]);

      const result = await userUpdateAvatar(account.token, formData);
      if (result?.data?.data?.success) {
        toast.success("Avatar is uploaded successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        const prepareData = {
          ...account.user,
          avatar_url: result?.data?.data?.avatar_url,
        };
        dispatch(updateUserProfile(prepareData));
        encryptStorage.setItem("user", prepareData);
      }
      onClose();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <Dialog className="upload" open={open} onClose={onClose}>
      <div className="upload_modal">
        <div className="upload_modal-wrapper">
          <p className="upload_modal-wrapper-close" onClick={() => onClose()}>
            <CloseIcon sx={{ fontSize: 32 }} color="#000000" />
          </p>
          <div className="upload_modal-wrapper-img">
            {/* <img src={IconMail} /> */}
            {account?.user?.avatar_url && (
              <div
                className="upload_modal-wrapper-img-avatar"
                style={{ backgroundImage: `url(${account?.user?.avatar_url})` }}
              ></div>
            )}
            {!account?.user?.avatar_url && (
              <div className="upload_modal-wrapper-img-name">
                {initials(
                  `${account?.user?.first_name} ${account?.user?.last_name}`
                )}
              </div>
            )}
          </div>
          <h1 className="upload_modal-wrapper-title">Edit Profile Photo!</h1>

          <FileUploader fileList={fileList} onFile={handleFile} />
          <div className="upload_modal-wrapper-footer">
            <button onClick={onClose} className="btn-cancel">
              <p className="button__text">Cancel</p>
            </button>
            <button onClick={handleUpload} className="btn-upload">
              <p className="button__text">
                {loading ? "Uploading..." : "Upload"}
              </p>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PhotoUploadModal;
