import React from "react";
import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import "react-phone-input-2/lib/style.css";
import "./index.scss";

const MobileInput = React.memo(
  ({ parseError, type, required, name, label, ...rest }) => (
    <Controller
      name={name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => {
        const errorMsg = error
          ? typeof parseError === "function"
            ? parseError(error)
            : error.message
          : rest.helperText;

        return (
          <Box>
            <PhoneInput
              {...rest}
              containerStyle={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "15px",
                position: "relative",
                width: "100%",
                border: "none",
              }}
              inputStyle={{
                boxShadow: "none",
                height: "61px",
                outline: "none",
                paddingTop: "22px",
                width: "100%",
                border: "none",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#EAEAEA",
                borderRadius: 30,
              }}
              buttonStyle={{
                borderTopLeftRadius: 30,
                borderBottomLeftRadius: 30,
              }}
              specialLabel={label}
              country="us"
              preferredCountries={["us", "ca"]}
              countryCodeEditable={false}
              enableSearch={true}
              value={value}
              inputProps={{ name }}
              onChange={(value) => onChange(`+${value}`)}
              disableDropdown={true}
            />

            {errorMsg && (
              <Typography variant="body2" color="error" sx={{ mt: 0.25 }}>
                {errorMsg}
              </Typography>
            )}
          </Box>
        );
      }}
    />
  )
);

export default MobileInput;
