import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
}

const FormContainerCore = ({
  defaultValues = {},
  onSuccess = () => {},
  validation,
  FormProps,
  mode = 'onSubmit',
  children,
}) => {
  const methods = useForm({
    defaultValues,
    mode,
    ...(validation ? { resolver: yupResolver(validation) } : {}),
  })
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSuccess)}
        noValidate
        style={formStyle}
        {...FormProps}
      >
        {children}
      </form>
    </FormProvider>
  )
}

const FormContainer = React.memo((props) => {
  if (!props.formContext && !props.handleSubmit) {
    return <FormContainerCore {...props} />
  }
  if (props.handleSubmit && props.formContext) {
    return (
      <FormProvider {...props.formContext}>
        <form
          noValidate
          {...props.FormProps}
          style={formStyle}
          onSubmit={props.handleSubmit}
        >
          {props.children}
        </form>
      </FormProvider>
    )
  }
  if (props.formContext && props.onSuccess) {
    return (
      <FormProvider {...props.formContext}>
        <form
          onSubmit={props.formContext.handleSubmit(props.onSuccess)}
          style={formStyle}
          noValidate
          {...props.FormProps}
        >
          {props.children}
        </form>
      </FormProvider>
    )
  }

  return <div>Incomplete setup of FormContainer..</div>
})

export default FormContainer
