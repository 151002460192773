import { Grid } from "@mui/material";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { data } from "../Data";

const PlanningGrid = () => {
  return (
    <Grid container>
      {data?.planning?.map((data, index) => (
        <Grid item key={`planningGrid_${index}`} md={6}>
          <AnimationOnScroll animateOnce={true} initiallyVisible={true} animateIn="animate__slideInUp">
            <div className="processe_tile">
              <div className="processe_image">
                <img src={data.image.default} alt="not found" />
              </div>
              <div className="processe_parent">
                <div className="processe_label">
                  <p>{data.name}</p>
                </div>
                <div className="processe_description">
                  <p className="text-center">{data.description}</p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanningGrid;
