import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteProfile } from "../../../Services/User";
import { getLogout } from "../../../Redux-Saga/Redux/account";
import { toast } from "react-toastify";

import Amplify from "aws-amplify";
import { encryptStorage } from "../../../Components/Helpers/commonMethods";
import { Button, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ProfileFooter = ({ csv }) => {
  useEffect(() => {
    encryptStorage.getItem("user");
  }, []);

  const [loading, setLoading] = useState(false);
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const handleDeleteMyProfile = useCallback(async () => {
    if (account.token) {
      setLoading(true);
      await Amplify.Auth.deleteUser();
      await deleteProfile(account?.token);
      setLoading(false);
      dispatch(getLogout());
    }
  }, [account, dispatch]);

  const handleCsvDownload = useCallback(() => {
    if (csv) {
      const csvContent = `data:text/csv;charset=utf-8,${csv}`;
      window.open(encodeURI(csvContent));
    } else {
      toast.error("We are still generating the CSV file for download", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [csv]);

  return (
    <Box py={3}>
      <Box mb={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#000" }}>
          Account Closure
        </Typography>
      </Box>

      <Box>
        <Button variant="contained" onClick={handleCsvDownload} sx={{ mr: 1 }}>
          Download Data
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="inherit"
          onClick={handleDeleteMyProfile}
        >
          Delete Account
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ProfileFooter;
