import React, { useState } from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import FormContainer from "../../Form/FormContainer";
import { LoadingButton } from "@mui/lab";
import Input from "../../Form/Input";
import { addCollaborator } from "../../../Services/User";
import { useSelector } from "react-redux";
import { COLLABORATOR_KEY } from "../../../constants";

const AddCollaboratorModal = ({ open, onClose, onOk }) => {
  const account = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please provide first name."),
    lastName: Yup.string().required("Please provide last name."),
    email: Yup.string()
      .nullable()
      .trim()
      .required("Please provide a valid email.")
      .email("Please provide email.")
  });
  const handleFormSubmit = async (values) => {
    setLoading(true);
    await addCollaborator({
      email: account?.user?.email,
      duputy_email: values?.email,
      deputy_first_name: values?.firstName,
      deputy_last_name: values?.lastName,
      post_death_ids: COLLABORATOR_KEY
    });
    setLoading(false);
    onOk();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 4, minWidth: "500px" } }}
    >
      <FormContainer validation={validationSchema} onSuccess={handleFormSubmit}>
        <Box px={3} py={8}>
          <Box position="absolute" top="20px" right="20px">
            <CloseIcon
              sx={{ fontSize: 32, cursor: "pointer" }}
              color="#000000"
              onClick={() => onClose()}
            />
          </Box>

          <Box mb={4}>
            <Typography
              variant="h2"
              align="center"
              color="primary.dark"
              fontWeight={600}
            >
              Add Collaborator
            </Typography>
          </Box>

          <Box mb={3}>
            <Input
              fullWidth
              normalInput={true}
              name="firstName"
              variant="outlined"
              label="First Name"
            />
          </Box>
          <Box mb={3}>
            <Input
              fullWidth
              normalInput={true}
              name="lastName"
              variant="outlined"
              label="Last Name"
            />
          </Box>
          <Box mb={3}>
            <Input
              normalInput={true}
              fullWidth
              name="email"
              variant="outlined"
              label="Email"
            />
          </Box>

          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} sm={8}>
              <LoadingButton
                loading={loading}
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Add Collaborator
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </FormContainer>
    </Dialog>
  );
};

export default AddCollaboratorModal;
