import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, MenuItem, Typography } from "@mui/material";

import { getLogout, setCheckAccessRequest } from "../../Redux-Saga/Redux/account";
import { increaseBoardCounting, setRedirecting, setShowIframe, setActiveMenu } from "../../Redux-Saga/Redux/ui";
import { PLAN_SURVEY_ID, POST_DEATH_SURVEY_ID } from "../../constants";
import UpgradeDialog from "../../Components/Dialogs/UpgradeDialog";
import AccessDialog from "../../Components/Dialogs/AccessDialog";
import { sendRequest } from "../../Services/User";
import NotificationDialog from "../../Components/Dialogs/NotificationDialog";

const PLAN_SUBMENU_LIST = [
  {
    label: "Assign Deputies",
    url: "/dashboard",
    id: "plan-10",
  },
  {
    label: "Critical Healthcare",
    url: "/dashboard",
    id: 'plan-36',
  },
  {
    label: "Dependents",
    url: "/dashboard",
    id: 'plan-5',
  },
  {
    label: "Personal Information",
    url: "/dashboard",
    id: "plan-9",
  },
  {
    label: "Legal Estate Planning",
    url: "/dashboard",
    id: 'plan-1',
  },
  {
    label: "Real Estate & Property",
    url: "/dashboard",
    id: 'plan-34',
  },
  {
    label: "Financial Assets",
    url: "/dashboard",
    id: 'plan-6',
  },
  {
    label: "Digital",
    url: "/dashboard",
    id: 'plan-11',
  },
  {
    label: "Recurring Expenses",
    url: "/dashboard",
    id: 'plan-7',
  },
  {
    label: "Funeral Arrangements",
    url: "/dashboard",
    id: 'plan-2',
  },
  {
    label: "Making Peace",
    url: "/dashboard",
    id: 'plan-35',
  },
  {
    label: "Veterans Benefits",
    url: "/dashboard",
    id: 'plan-4',
  },
];

const POST_DEATH_SUBMENU_LIST = [
  {
    label: "First 48 Hours",
    url: "/dashboard",
    id: "post-1",
  },
  {
    label: "1 Week",
    url: "/dashboard",
    id: "post-2",
  },
  {
    label: "1 Month",
    url: "/dashboard",
    id: "post-3",
  },
  {
    label: "3 Months",
    url: "/dashboard",
    id: "post-4",
  },
  {
    label: "9 Months",
    url: "/dashboard",
    id: "post-5"
  },
  {
    label: "Tax Year",
    url: "/dashboard",
    id: "post-6",
  },
  {
    label: "Probate Specific Tasks",
    url: "/dashboard",
    id: "post-7",
  },
  {
    label: "View All",
    url: "/dashboard",
    id: "post-8",
  },
];

const Sidebar = () => {
  const { sessionInfo, operateEmail, checkAccessRequest } = useSelector((state) => state.account);
  const { isRedirecting } = useSelector((state) => state.ui);
  const [activeSubMenu, setActiveSubMenu] = useState();
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showAccessRequest, setShowAccessRequest] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser = {}, assignUserList, myDeputies } = sessionInfo;
  const { survey_id: userSurveyId } = currentUser;

  const selectedOwner = useMemo(() => {
    return assignUserList?.find((itm) => itm.email === operateEmail);
  }, [assignUserList, operateEmail]);

  const deputiesRequestedAccess = useMemo(() => {
    return myDeputies?.filter(deputy => deputy?.is_access === `Requested full access`) || [];
  }, [myDeputies]);

  const [showRequested, setShowRequested] = useState(deputiesRequestedAccess.length > 0);

  const handleClickSubMenu = useCallback((item) => {
    if (isRedirecting) return;

    if (location.pathname === '/dashboard') {
      if (userSurveyId === POST_DEATH_SURVEY_ID) {
        document.getElementById('qualtricsId').contentWindow.postMessage(`${item.id}`, '*');
        setActiveSubMenu(item);
        dispatch(setActiveMenu(item))
      } else {
        dispatch(setShowIframe(false));
        navigate(item.url);
        dispatch(increaseBoardCounting());
        dispatch(setRedirecting(true));
  
        setTimeout(() => {
          document.getElementById('qualtricsId').contentWindow.postMessage(`${item.id}`, '*');
          setActiveSubMenu(item);
          dispatch(setActiveMenu(item))
        }, 15000);
  
        setTimeout(() => dispatch(setRedirecting(false)), 20000);
      }
    } else {
      dispatch(setShowIframe(false));
      navigate(item.url);
      dispatch(setRedirecting(true));

      setTimeout(() => {
        document.getElementById('qualtricsId').contentWindow.postMessage(item.id, '*');
        setActiveSubMenu(item);
        dispatch(setActiveMenu(item))
      }, 15000);

      setTimeout(() => dispatch(setRedirecting(false)), 20000);
    }
  }, [navigate, dispatch, location, isRedirecting, userSurveyId]);

  const handleClickDashboard = () => {
    dispatch(increaseBoardCounting());
    setActiveSubMenu();
    dispatch(setActiveMenu(null))
    dispatch(setShowIframe(false));
  }

  const isLockHelp = useMemo(() => {
    const {
      isPromoted,
      planning_subscription,
      post_death_subscription,
      products = [],
    } = currentUser;
    if (isPromoted) return false;

    if (userSurveyId === PLAN_SURVEY_ID) {
      if (planning_subscription?.status === 'active') return false;
      
      const planningProducts = products.filter((it) => it.category === 'planning');
      return planningProducts.length > 0 ? false : true;
    }

    if (userSurveyId === POST_DEATH_SURVEY_ID) {
      if (post_death_subscription?.status === 'active') return false;
      
      const postDeathProducts = products.filter((it) => it.category === 'post_death');
      return postDeathProducts.length > 0 ? false : true;
    }

    return false
  }, [currentUser, userSurveyId]);

  const handleClickGetHelp = () => {
    if (isLockHelp) setShowUpgrade(true);
    else navigate('/help');
  }

  const handleClickDocuments = () => {
    if (isLockHelp) setShowUpgrade(true);
    else navigate('/documents');
  }

  const handleRequestAccess = async () => {
    const { email, owner_id, qualtrics_user_id: qualtricsUserId } = selectedOwner;
    try {
      const payload = {
        qualtricsUserId,
        ownerEmail: email,
        owner_id,
        deputy_id: sessionInfo?.currentUser?.id,
      };

      await sendRequest({ path: "/auth/hrAccessRequest", method: "post", payload });
      setShowAccessRequest(false);
    } catch (err) {
      console.log('Failed to get full request: ', err);
    }
  }

  const handleCloseNotifyDialog = async () => {
    setShowRequested(false);
    dispatch(setCheckAccessRequest(false))
  }

  const submenus = useMemo(() => {
    if (selectedOwner) {
      const sectionIds = selectedOwner?.plan_ids?.split(',') || [];
      const isAccess = selectedOwner?.is_access;
      if (selectedOwner?.survey_id === POST_DEATH_SURVEY_ID) return POST_DEATH_SUBMENU_LIST;
      if (isAccess === `Accepted`) return PLAN_SUBMENU_LIST;

      return PLAN_SUBMENU_LIST.filter((it) => {
        const listId = it.id.replace("plan-", "");
        return sectionIds.includes(listId);
      })
    }

    if (userSurveyId === POST_DEATH_SURVEY_ID) return POST_DEATH_SUBMENU_LIST;
    else if (userSurveyId === PLAN_SURVEY_ID) return PLAN_SUBMENU_LIST;
    return [];
  }, [userSurveyId, selectedOwner]);

  useEffect(() => {
    window.addEventListener("message", (data) => {
      const selectedId = data?.data;

      if (selectedId === 'plan-main') {
        setActiveSubMenu();
      } else if (selectedId === 'show-lock') {
        setShowUpgrade(true);
      } else if (selectedId === 'show-access-request') {
        setShowAccessRequest(true);
      } else {
        const activeMenu = submenus.find(it => it.id === selectedId);
        if (activeMenu) {
          setActiveSubMenu(activeMenu);
          dispatch(setActiveMenu(activeMenu))
        }
      }
    })
  }, [submenus, dispatch]);

  const isPostDeathSurvey = useMemo(() => {
    return userSurveyId === POST_DEATH_SURVEY_ID
  }, [userSurveyId])

  return (
    <Box p={2}>
      <MenuItem
        sx={{ py: 1.6 }}
        component={Link}
        to="/dashboard"
        onClick={handleClickDashboard}
      >
        <Typography
          component="span"
          color={location.pathname === "/dashboard" ? 'primary.light' : 'grey.600'}
          fontWeight={location.pathname === "/dashboard" ? 700 : 400}
        >
          My Dashboard
        </Typography>
      </MenuItem>

      {submenus.map((subMenuItem) => (
        <MenuItem
          key={subMenuItem.id}
          disabled={isRedirecting}
          onClick={() => handleClickSubMenu(subMenuItem)}
        >
          <Typography
            color={activeSubMenu?.id === subMenuItem.id ? 'primary.light' : 'grey.600'}
            fontWeight={activeSubMenu?.id === subMenuItem.id ? 700 : 400}
            sx={{ pl: 2, py: 0.25 }}
          >
            {subMenuItem.label}
          </Typography>
        </MenuItem>
      ))}

      <MenuItem sx={{ py: 1.6 }} onClick={handleClickGetHelp}>
        <Typography
          component="span"
          color={location.pathname === "/help" ? 'primary.light' : 'grey.600'}
          fontWeight={location.pathname === "/help" ? 700 : 400}
        >
          Get Help
        </Typography>
      </MenuItem>

      {isPostDeathSurvey && (
        <MenuItem sx={{ py: 1.6 }} component={Link} to="/collaborators">
          <Typography
            component="span"
            color={location.pathname === "/collaborators" ? 'primary.light' : 'grey.600'}
            fontWeight={location.pathname === "/collaborators" ? 700 : 400}
          >
            Collaborators
          </Typography>
        </MenuItem>
      )}

      <MenuItem sx={{ py: 1.6 }} component={Link} to="/my-account">
        <Typography
          component="span"
          color={location.pathname === "/my-account" ? 'primary.light' : 'grey.600'}
          fontWeight={location.pathname === "/my-account" ? 700 : 400}
        >
          My Account
        </Typography>
      </MenuItem>

      <MenuItem sx={{ py: 1.6 }} component={Link} to="/plan">
        <Typography
          component="span"
          color={location.pathname === "/plan" ? 'primary.light' : 'grey.600'}
          fontWeight={location.pathname === "/plan" ? 700 : 400}
        >
          Plans
        </Typography>
      </MenuItem>

      <MenuItem sx={{ py: 1.6 }} onClick={handleClickDocuments}>
        <Typography
          component="span"
          color={location.pathname === "/documents" ? 'primary.light' : 'grey.600'}
          fontWeight={location.pathname === "/documents" ? 700 : 400}
        >
          Documents
        </Typography>
      </MenuItem>

      <MenuItem sx={{ py: 1.6 }} onClick={() => dispatch(getLogout())}>
        <Typography component="span" fontWeight={400} color="grey.600">
          Sign Out
        </Typography>
      </MenuItem>

      {showUpgrade && (
        <UpgradeDialog handleClose={() => setShowUpgrade(false)} />
      )}

      {showAccessRequest && (
        <AccessDialog
          owner={selectedOwner}
          handleRequestAccess={handleRequestAccess}
          handleClose={() => setShowAccessRequest(false)}
        />
      )}

      {showRequested && checkAccessRequest && Boolean(deputiesRequestedAccess?.[0]) && (
        <NotificationDialog
          deputy={deputiesRequestedAccess?.[0]}
          onClose={handleCloseNotifyDialog}
        />
      )}
    </Box>
  );
};

export default Sidebar;
