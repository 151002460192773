import { Container, Stack } from "@mui/material";
import React from "react";
import { data } from "../Data";
import "./features_old.scss";

const Default = () => {
  return (
    <section className="features">
      <Container>
        <div className="text-center features-title">Unburden Family</div>

        <Stack direction="column" alignItems="center" spacing={2}>
          {data.features.map((data, index) => {
            return (
              <div className="featured" key={`featured_card_${index}`}>
                <div className="featured_card">
                  <div className="featured_card-header">
                    <div className="featured_card-header-container">
                      <img
                        className="featured_card-header-container-img"
                        src={data.image.default}
                        alt="Card Header"
                      />
                    </div>
                  </div>
                  <div className="featured_card-body">
                    <div className="featured_card-body-title">{data.name}</div>
                    <div className="featured_card-body-desc">
                      {data.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Stack>
      </Container>
    </section>
  );
};

export default Default;
