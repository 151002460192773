import React, { useState } from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { sendEmail } from "../../../../Services/User";
import config from "../../../../Config/config";
import FormContainer from "../../../../Components/Form/FormContainer";
import Input from "../../../../Components/Form/Input";

const RequestModal = ({ dialogClose }) => {
  const [isloading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const account = useSelector((state) => state.account);

  const sendRequest = (data) => {
    setIsLoading(true);
    console.log("Send Data", data);
    const { adminEmail } = config;
    const message = `Name=${data.fullname}\nEmail=${data.email}\nCompany=${data.company}\nName=${data.phoneNumber}\nRole=${data.role}\nName=${data.message}\n`;
    const prepareData = {
      fullname: data.fullname,
      email: adminEmail,
      company: data.company,
      message,
      phone_number: data.phoneNumber,
      role: data.role,
      subject: "Request a demo",
    };

    sendEmail(account.token, prepareData)
      .then((res) => {
        console.log("API response", res.status);
        if (res.status === 200) {
          toast.success(
            "Thank you for contacting us, we will get back to you as soon as possible",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setShowSuccess(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("err", error);
        setIsLoading(false);
        setShowSuccess(true);
        setIsLoading(false);
      });
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    fullname: Yup
      .string()
      .trim()
      .max(25, "Must be 15 characters or less")
      .required("Please enter your name"),
    email: Yup
      .string()
      .trim()
      .email("Invalid email address")
      .required("Please enter your email"),
    phoneNumber: Yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid"),
    company: Yup
      .string()
      .required("Please enter your company"),
    role: Yup
      .string()
      .required("Please enter your role"),
  });

  const initialValues = {
    fullname: "",
    email: "",
    phoneNumber: "",
    company: "",
    role: "",
    message: "",
  };

  if (showSuccess) {
    return (
      <Dialog open={true} onClose={dialogClose}>
        <Box maxWidth={500}>
          <Box onClick={dialogClose} px={3} py={2} display="flex" justifyContent="flex-end">
            <CloseIcon sx={{ fontSize: 32 }} />
          </Box>
    
          <Box display="flex" justifyContent="center">
            <TaskAltIcon sx={{ fontSize: 72, color: "#27ee27" }} />
          </Box>
    
          <Typography sx={{ p: 4 }} align="center">
            Thank you for contacting us, we will get back to you as soon as possible
          </Typography>
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onClose={dialogClose}>
      <Box maxWidth={500}>
        <Box onClick={dialogClose} px={3} py={2} display="flex" justifyContent="flex-end">
          <CloseIcon sx={{ fontSize: 32 }} />
        </Box>

        <Typography variant="h2" color="primary" align="center" sx={{ mb: 2 }}>
          Demo Request
        </Typography>

        <Box px={2} mb={4}>
          <Typography color="#545454" align="center">
            Learn more about how Peacefully can work with your organization.
          </Typography>
        </Box>

        <Box px={3} pb={4}>
          <FormContainer
            validation={validationSchema}
            defaultValues={initialValues}
            onSuccess={(values) => sendRequest(values)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  fullWidth
                  variant="filled"
                  name="fullname"
                  disabled={isloading}
                  label="Name"
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  fullWidth
                  variant="filled"
                  name="email"
                  disabled={isloading}
                  label="Email"
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  fullWidth
                  variant="filled"
                  name="phoneNumber"
                  disabled={isloading}
                  label="Phone Number"
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  fullWidth
                  variant="filled"
                  name="company"
                  disabled={isloading}
                  label="Company"
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  fullWidth
                  variant="filled"
                  name="role"
                  disabled={isloading}
                  label="Role"
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  fullWidth
                  variant="filled"
                  name="message"
                  multiline
                  rows={3}
                  disabled={isloading}
                  sx={{ '& .MuiInputBase-root': { border: 'solid 1px #ced7de', borderRadius: 8 } }}
                  label="Any additional information that would be helpful (optional)"
                />
              </Grid>

              <Grid item xs={12}>
                <Box px={8}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isloading}
                    size="large"
                  >
                    Continue
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </FormContainer>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RequestModal;
