import React, { useState, useEffect } from "react";
import { Card, CardContent, Box, Typography, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddReminderModal from "../../../../Components/modals/AddReminder";
import SuccessModal from "../../../../Components/modals/Success";
import dayjs from "dayjs";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { getSessionInfoSuccess } from "../../../../Redux-Saga/Redux/account";
import { useDispatch } from "react-redux";

const REMINDER_SECTION = "REMINDER_SECTION";
const REMINDER_BUTTON = "REMINDER_BUTTON";

const PER_PAGE_COUNT = 5;
const ReminderSection = ({ type }) => {
  const account = useSelector((state) => state.account);

  const [page, setPage] = useState(0);
  const [successInfo, setSuccessInfo] = useState(null);
  const [addReminder, setAddReminder] = useState(false);
  const [reminderData, setReminderData] = useState(null);
  const [reminderList, setReminderList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setReminderList([...account?.sessionInfo?.reminders]);
  }, [account]);

  const handleAddReminder = (value) => {
    setReminderList(value);
    dispatch(getSessionInfoSuccess({
      ...account?.sessionInfo,
      reminders: value
    }))
  };

  const handleAddPage = () => {
    if (page + 1 < reminderList.length / PER_PAGE_COUNT) {
      setPage(page + 1);
    }
  };

  const handleSubPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <>
      {type === REMINDER_SECTION && (
        <Card
          sx={{
            borderRadius: 3.25,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow:
              "0px 2px 6px 1px #eee, 0px 1px 1px 0px #eee, 0px 1px 3px 0px #eee"
          }}
        >
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="h6" sx={{ fontSize: "18px" }}>
                Scheduled Reminders
              </Typography>
              <IconButton
                variant="contained"
                sx={{ background: "#4B6EAE", color: "#fff" }}
                onClick={() => {
                  setAddReminder(true);
                  setReminderData(null);
                }}
              >
                <AddIcon size="large" />
              </IconButton>
            </Box>
            <Box sx={{}}>
              {reminderList?.length === 0 && (
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  You currently have no reminders.
                </Typography>
              )}
              {reminderList?.length > 0 &&
                reminderList
                  .sort((itemA, itemB) => itemA.id - itemB.id)
                  .slice(page * PER_PAGE_COUNT, (page + 1) * PER_PAGE_COUNT)
                  .map((item) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        color={"primary.main"}
                        sx={{ fontWeight: "700" }}
                      >
                        {item.title} Reminder on{" "}
                        {dayjs(item.remind_at).format("DD/MM/YYYY hh:mm a")}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setAddReminder(true);
                          setReminderData({
                            title: item.title,
                            remindAt: dayjs(item.remind_at),
                            repeat: item.repeat,
                            toEmail: item.to_email || "",
                            toPhone: item.to_phone || "",
                            id: item.id
                          });
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  ))}
            </Box>
          </CardContent>

          <Box display="flex" justifyContent="flex-end" pb={1} px={1}>
            <IconButton size="small" onClick={handleSubPage}>
              <KeyboardArrowLeftIcon />
            </IconButton>

            <IconButton size="small" onClick={handleAddPage}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Card>
      )}
      {type === REMINDER_BUTTON && (
        <Box
          sx={{
            borderRadius: 30,
            cursor: "pointer",
            boxShadow: "0px 0px 3px 1px #ddd"
          }}
          height={60}
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            setAddReminder(true);
            setReminderData(null);
          }}
        >
          <AccessAlarmIcon
            sx={{
              color: "primary.light",
              fontWeight: 700,
              fontSize: 30,
              mr: 1
            }}
          />
          <Typography sx={{ fontWeight: 700 }} color="primary.light">
            Schedule Reminder
          </Typography>
        </Box>
      )}
      <AddReminderModal
        reminderData={reminderData}
        open={addReminder}
        onClose={() => setAddReminder(false)}
        onOk={(values) => {
          handleAddReminder(values.data);
          setAddReminder(false);
          let title = "",
            desc = "";
          if (values?.type === "add") {
            title = "Reminder Created";
            desc =
              "Your reminder has been created! You can view and edit your reminders on your dashboard.";
          } else if (values?.type === "delete") {
            title = "Reminder Removed";
            desc =
              "Your reminder has been removed! You can view and edit your reminders on your dashboard.";
          } else if (values?.type === "update") {
            title = "Reminder Updated";
            desc =
              "Your reminder has been updated! You can view and edit your reminders on your dashboard.";
          }
          setSuccessInfo({
            title: title,
            desc: desc
          });
        }}
      />

      <SuccessModal
        title={successInfo?.title}
        description={successInfo?.desc}
        open={!!successInfo}
        onClose={() => setSuccessInfo(null)}
        onOk={() => setSuccessInfo(false)}
      />
    </>
  );
};

export default ReminderSection;
