import { Container, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RequestModal from "../../Pages/Home/modal/RequestModal";
import { CONTACT_PHONE } from "../../Assets/Consts/contact";
import "./footer.scss";

const PUBLIC_PAGES = [
  "/",
  "/getstarted",
  "/signupnow",
  "/privacy-policy",
  "/employers/learn-more",
  "/terms-services",
];

const Footer = () => {
  let location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const isPublic = useMemo(() => {
    return PUBLIC_PAGES.includes(location.pathname);
  }, [location.pathname]);

  const dt = new Date();

  if (isPublic) {
    return (
      <>
        <footer className="foot">
          <div className="foot_con">
            <Container>
              <Grid spacing={2} container>
                <Grid item sm={12} md={5}>
                  <div className="foot_logo">
                    <Link to="/">
                      <h1 className="foot_logo-title">Peacefully</h1>
                    </Link>
                    <p className="foot_logo-desc"></p>
                    <a
                      className="foot_logo-copyright"
                      href="/copy-right"
                      target="_blank"
                    >
                      ©Peacefully {dt.getFullYear()} All Rights Reserved
                    </a>
                  </div>
                </Grid>

                <Grid container item sm={12} md={7}>
                  <Grid item sm={3} md={3}>
                    <div className="foot_widgets">
                      <h3 className="foot_widgets-title">Support</h3>
                      <ul className="foot_widgets-list">
                        <li>
                          <div
                            className="foot_widgets-list-link"
                            onClick={(e) => {
                              e.preventDefault();
                              setDialogOpen(true);
                            }}
                          >
                            Request a Demo
                          </div>
                        </li>
                        <li>
                          <a
                            className="foot_widgets-list-link"
                            href="https://peacefully.com/contact-us"
                          >
                            Contact
                          </a>
                        </li>
                        <li>
                          <a
                            className="foot_widgets-list-link"
                            href="https://guide.peacefully.com/"
                          >
                            Articles
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Grid>

                  <Grid item sm={3} md={3}>
                    <div className="foot_widgets">
                      <h3 className="foot_widgets-title">Information</h3>
                      <ul className="foot_widgets-list">
                        <li>
                          <a
                            className="foot_widgets-list-link"
                            href="https://peacefully.com/privacy-policy"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            className="foot_widgets-list-link"
                            href="https://peacefully.com/terms-services"
                          >
                            Terms of Use
                          </a>
                        </li>
                        <li>
                          <Link
                            className="foot_widgets-list-link"
                            to="/auth/signin"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="foot_widgets-list-link"
                            to="/ada-compliance"
                          >
                            ADA Compliance
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Grid>

                  <Grid item sm={3} md={3}>
                    <div className="foot_widgets">
                      <h3 className="foot_widgets-title">Contact</h3>
                      <ul className="foot_widgets-list">
                        <li>
                          <a
                            className="foot_widgets-list-link"
                            href={`tel:${CONTACT_PHONE}`}
                          >
                            {CONTACT_PHONE}
                          </a>
                        </li>
                        <li>
                          <a
                            className="foot_widgets-list-link"
                            href="mailto: hello@peacefully.io"
                          >
                            hello@peacefully.io
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Grid>

                  <Grid item sm={3} md={3}>
                    <ul className="foot_widgets-list-social">
                      <li>
                        <a
                          href="https://www.instagram.com/wearepeacefully"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            style={{
                              color: "#FB3958",
                            }}
                            className="fa fa-instagram"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/peacefullyio"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/peacefully"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <div className="foot-copyright"></div>
            </Container>
          </div>
          <div className="foot_pol">
            <Container>
              <p className="foot_pol-desc">
                While Peacefully operates in most states, there may be some
                restrictions.
              </p>
              <p className="foot_pol-desc">
                DISCLAIMER: While Peacefully supports end-of-life planning with
                services and templates, it's important to note we are not a law
                firm and cannot replace licensed attorney services. Our tools
                help organize preferences but don't substitute for personalized
                legal advice. Legal matters are intricate, and we recommend
                consulting a licensed attorney for complex questions. Their
                expertise ensures your specific situation is thoroughly
                addressed, contributing to the legality of your end-of-life
                plans and your peace of mind.
              </p>
            </Container>
          </div>
        </footer>

        {dialogOpen && (
          <RequestModal dialogClose={() => setDialogOpen(false)} />
        )}
      </>
    );
  }

  return null;
};

export default Footer;
