export const PUBLIC_URLS = ["/auth/signin", "/auth/reset", "/auth/signup"];

export const BASIC_FEATURES = [
  "Bank-level security with encryption",
  "Step-by-step guidance",
  "Best-in-class concierge support",
  "Automatic form filling",
  "Legal documents assistance",
  "Account collaboration and sharing",
];

export const POST_DEATH_SURVEY_ID = "SV_6leaBsba9TgqbD8";

export const PLAN_SURVEY_ID = "SV_a2ywRReYvW1UoRM";

export const COLLABORATOR_KEY = "Collaborator";
