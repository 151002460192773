import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrivateLayout from "../../../Layouts/PrivateLayout";
import DashboardIcon from "../../../Assets/Images/dashboard-icon.png";

const SignupProgress = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/dashboard');
    }, 20000);
    // eslint-disable-next-line
  }, []);

  return (
    <PrivateLayout>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        height="100%"
      >
        <Box
          width={100}
          height={100}
          borderRadius={50}
          bgcolor="#D4ECFF"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={4}
        >
          <img alt="Creating Dashboard" src={DashboardIcon} />
        </Box>

        <Typography color="primary.dark" align="center" variant="h2" mb={3}>
          We’re creating your Dashboard.
        </Typography>

        <Typography align="center" color="grey.700" mb={4}>
          Hang tight while we finish setting you up!
        </Typography>

        <CircularProgress />
      </Box>
    </PrivateLayout>
  );
}

export default SignupProgress;
