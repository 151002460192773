import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Drawer, Toolbar } from "@mui/material";
import { debounce } from "lodash"

import Header from "./Header";
import Sidebar from "./Sidebar";
import AuthenticatedFooter from "./AuthenticatedFooter";
import IframLoading from "./IframeLoading";
import { createOwner } from "../Services/User";
import Loading from "./Loading";

const PrivateLayout = ({ children }) => {
  const { token, isAuthenticated } = useSelector((state) => state.account);
  const [toggle, setToggle] = useState(true);
  const navigate = useNavigate();
  // This variable is needed to wait until the qualtrics user is created
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleApiRequest = debounce(async (data) => {
      switch (data.mode) {
        case 'owner-create':
          await createOwner(token, data);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 20000);
          break;
        default:
      }
    }, 1000);

    const eventHandler = (data) => {
      if (data?.data?.type === 'api') {
        handleApiRequest(data?.data);
      }
    }

    window.addEventListener("message", eventHandler);
    return () => window.removeEventListener("message", eventHandler);
    // eslint-disable-next-line
  }, []);

  if (!isAuthenticated) navigate("/auth/signin");

  return (
    <Box>
      <Header toggle={toggle} onChangeToggle={setToggle} />

      <Box
        flex={1}
        display="flex"
        sx={{ paddingTop: '68px' }}
      >
        <Drawer
          anchor="left"
          open={toggle}
          variant="persistent"
          onClose={() => setToggle(false)}
          sx={{
            width: { xs: 0, md: 256 },
            [`& .MuiDrawer-paper`]: { width: 256, boxSizing: 'border-box', zIndex: 1000 },
          }}
        >
          <Toolbar sx={{ height: 68 }} />
          <Sidebar />
        </Drawer>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          position="relative"
          id="board"
          sx={{
            height: { xs: 'calc(100vh - 61px)', md: 'calc(100vh - 68px)' },
            overflow: 'auto'
          }}
        >
          <Box flex={1} display="flex" flexDirection="column">
            {loading ? <Loading /> : children}
          </Box>

          <IframLoading containerLoading={loading} />
          <AuthenticatedFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
