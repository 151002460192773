import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormContainer from "../../../Components/Form/FormContainer";
import Input from "../../../Components/Form/Input";

const ProfilePlan = () => {
  const account = useSelector((state) => state.account);

  const navigate = useNavigate();

  const initialValues = {
    name: "",
    type: "",
    price: "",
  };

  if (!account?.sessionInfo) return null;

  return (
    <Box py={3}>
      <Box mb={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#000" }}>
          Plan Details
        </Typography>
      </Box>

      <FormContainer defaultValues={initialValues} onSuccess={() => {}}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Input
              fullWidth
              name="name"
              variant="filled"
              label="Plan Name"
              readOnly
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <Input
              fullWidth
              name="type"
              variant="filled"
              label="Plan Type"
              readOnly
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <Input
              fullWidth
              name="price"
              variant="filled"
              label="Price"
              readOnly
              disabled
            />
          </Grid>
        </Grid>
      </FormContainer>

      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/plan")}
        >
          Update Subscription
        </Button>
      </Box>
    </Box>
  );
};

export default ProfilePlan;
