import React from "react";
import PublicLayout from "../../Layouts/PublicLayout";
import HeroSignUpNow from "./HeroSignUpNow";

const SignUpNow = () => {
  return (
    <PublicLayout>
      <HeroSignUpNow />
    </PublicLayout>
  );
};

export default SignUpNow;
