import { Container } from "@mui/material";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import PlanningGrid from "./PlanningGrid";
import "./process_old.scss";

const index = () => {
  return (
    <section className="processe_section">
      <Container>
        <AnimationOnScroll
          initiallyVisible={true}
          animateOnce={true}
          animateIn="animate__slideInDown"
        >
          <div className="text-center processe_section-header">
            <h5 className="processe_section-header-title">
              Providing support <br />
              when it matters the most
            </h5>
          </div>
        </AnimationOnScroll>

        <AnimationOnScroll animateOnce={true} initiallyVisible={true} animateIn="animate__slideInUp">
          <div className="processe_section-header">
            <p className="processe_section-header-desc">
              We understand the process inside and out, and we're committed to
              making sure you and your family receive the comprehensive care and
              support you deserve, every step of the way.
            </p>
          </div>
        </AnimationOnScroll>

        <PlanningGrid />
      </Container>
    </section>
  );
};

export default index;
