import React from "react";
import { TextField, Typography, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";

const Select = React.memo(
  ({
    parseError,
    normalInput = false,
    type,
    required,
    name,
    label,
    optionList,
    sx,
    ...rest
  }) => {
    return (
      <Controller
        name={name}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error }
        }) => (
          <TextField
            {...rest}
            name={name}
            label={label}
            value={value || ""}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            error={invalid}
            select
            helperText={
              <Typography variant="body2">
                {error
                  ? typeof parseError === "function"
                    ? parseError(error)
                    : error.message
                  : rest.helperText}
              </Typography>
            }
          >
            {optionList.map((optionItem) => (
              <MenuItem value={optionItem.value}>{optionItem.label}</MenuItem>
            ))}
          </TextField>
        )}
      />
    );
  }
);

export default Select;
