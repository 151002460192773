import React, { useState } from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";

const PublicLayout = ({ children }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <Box display="flex" flexDirection="column">
      <Header toggle={toggle} onChangeToggle={setToggle} />

      <Box sx={{ paddingTop: '68px' }}>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          overflow="auto"
          height="calc(100vh - 68px)"
        >
          {children}
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default PublicLayout;
