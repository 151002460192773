import { Box, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Amplify from "aws-amplify";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormContainer from "../../../Components/Form/FormContainer";
import Input from "../../../Components/Form/Input";
import IconCheck from "../../../Assets/Images/icon_check.svg";
import { BASIC_FEATURES } from "../../../constants";
import PublicLayout from "../../../Layouts/PublicLayout";
import FooterFixNav from "../Component/FooterFixNav";
import Verification from "./Verification";
import { userForgotPassword } from "../../../Services/User";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [useremail, setUseremail] = useState();
  const [showVerification, setShowVerification] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup
      .string()
      .nullable()
      .trim()
      .required('Email is required')
      .email('Invalid Email'),
  });

  const initialValues = { email: '' };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true)
      const { email } = values;
      setUseremail(email);
      await Amplify.Auth.forgotPassword(email);
      setShowVerification(true);
    } catch (err) {
      console.log('login failed', err.code);
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false)
    }
  }

  const handleUpdatePassword = async ({ code, password }) => {
    try {
      setLoading(true);
      await Amplify.Auth.forgotPasswordSubmit(useremail, code, password);
      await userForgotPassword({ email: useremail, password });

      toast.success("User password Reset Successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate("/auth/signin");
    } catch (err) {
      console.log('Update Password Failed: ', err);
      const errorMsg = err?.message || err?.error?.message
      toast.error(errorMsg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <PublicLayout>
      <Box flex={1} display="flex">
        <Box
          width={500}
          flexDirection="column"
          sx={{ display: { xs: 'none', lg: 'flex' }}}
        >
          <Box px={6} pt={9} flex={1}>
            <Typography
              variant="h1"
              color="primary"
              sx={{ mb: 3 }}
            >
              Welcome back to Peacefully!
            </Typography>

            <Typography
              color="#000"
              sx={{ fontSize: 18, mb: 5 }}
            >
              Let Peacefully help you and your family stay organized when it matters the most.
            </Typography>

            {BASIC_FEATURES.map((feature, index) => (
              <Box
                display="flex"
                alignItems="center"
                mb={3}
                key={index}
              >
                <img
                  className="auth_container-side-content-item-img"
                  src={IconCheck}
                  alt="check"
                />

                <Typography sx={{ ml: 1.5 }}>{feature}</Typography>
              </Box>
            ))}

            <Typography color="#000" sx={{ mt: 4, mb: 7, fontSize: 18 }}>
              We use state-of-the art encryption and prioritize security. Please do your part to protect your data; keep your password secure and do not share it with anyone.
            </Typography>
          </Box>

          <Box pb={5} pr={4}>
            <FooterFixNav />
          </Box>
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          sx={{ bgcolor: { xs: '#fff', sm: '#f2f7f7' } }}
        >
          <Box flex={1} display="flex" py={4} px={2} justifyContent="center" alignItems="center">
            <Box
              width="100%"
              maxWidth={450}
              borderRadius={4}
              backgroundColor="#fff"
              sx={{ px: { xs: 2, sm: 5 }, py: { xs: 4, sm: 8 }, boxShadow: { xs: 'none', sm: '0 2px 10px 0 rgba(0,0,0,.15)' } }}
            >
              <Box mb={4}>
                <Typography
                  variant="h2"
                  align="center"
                  whiteSpace="nowrap"
                  component={Link}
                  to="/auth/reset"
                >
                  Forgot Your Password?
                </Typography>
              </Box>

              <Box mb={4}>
                <Typography color="#545454">
                  That’s ok. It happens. Let’s get you a new password.
                </Typography>
              </Box>

              <FormContainer
                validation={validationSchema}
                defaultValues={initialValues}
                onSuccess={handleFormSubmit}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="email"
                      label="Email"
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={loading}
                        size="large"
                      >
                        Reset Password
                      </LoadingButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
                      <Typography
                        sx={{ mr: 0.5 }}
                        component="span"
                        color="text.secondary"
                      >
                        {`Already have an account?`}
                      </Typography>

                      <Typography
                        color="primary.light"
                        sx={{ cursor: 'pointer' }}
                        component={Link}
                        to={`/auth/signin${window.location.search}`}
                      >
                        Sign In
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </FormContainer>
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'block', lg: 'none' }}}>
            <FooterFixNav />
          </Box>
        </Box>
      </Box>

      {showVerification && (
        <Verification
          handleClose={() => setShowVerification(false)}
          onSubmit={handleUpdatePassword}
          loading={loading}
        />
      )}
    </PublicLayout>
  );
};

export default ResetPassword;
