import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { isCompanyEmail } from '../utils';

const usePageTracking = ({ email }) => {
  const location = useLocation();

  useEffect(() => {
    if (!isCompanyEmail(email)) {
      const page = location.pathname + location.search;
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: page,
        },
      });
    }
  }, [location, email]);
};

export default usePageTracking;
