import React from "react";
import "./style.scss";

export default function AuthenticatedFooter() {
  return (
    <div className="dashboard_footer">
      Security and the protection and reliability of customer data is our most important concern. To ensure this, our servers are protected by high-end firewall systems and scans are performed regularly to ensure that any vulnerabilities are quickly found and patched. We use technology with SOC II Type II Certification, ISO 27001, 27017, and 27018 Certifications, HITRUST, and FedRAMP Authorization.
      <br /> <br />
      Peacefully is not a law firm and does not perform the same services provided by a licensed attorney. Peacefully, its services, and its forms and templates are not a substitute for the advice or service of a licensed attorney. We recommend that you seek out a local attorney for any legal questions and/or analysis.
    </div>
  );
}
