import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { encryptStorage } from "../../Components/Helpers/commonMethods";
import { Box, Button, IconButton, Typography } from "@mui/material";

const SubscriptionReminder = () => {
  const [subscriptionBar, setSubscriptionBar] = useState(
    encryptStorage.getItem("subscription_bar") ? true : false
  );
  let navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const { currentUser: { renewalDate, created_at, isPromoted, isLifetime } = {} } = account.sessionInfo;

  const handleCloseSubscriptionBar = () => {
    encryptStorage.setItem("subscription_bar", true);
    setSubscriptionBar(true);
  };

  const isIn24Hr = useMemo(() => {
    const createdTime = new Date(created_at).getTime();
    const currentTime = new Date().getTime();
    return currentTime - createdTime < 3600 * 1000 * 24;
  }, [created_at]);

  if (!!account?.sessionInfo?.assignUserList?.length) return null;

  if(isPromoted || isLifetime) {
    return <div></div>
  }

  return (
    <>
      {!renewalDate ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => navigate("/plan")}
          style={{ cursor: "pointer" }}
          bgcolor="#e6edf9"
          px={2}
          py={1}
        >
          <Typography sx={{ py: 1 }} variant="body1" color="#545454" fontWeight={600}>
            {isIn24Hr && (
              <Typography component="span" fontWeight={600} sx={{ mr: 1 }}>
                Special offer: Purchase your plan in the first 24 hours and save 20%
              </Typography>
            )}
          </Typography>

          <Button
            sx={{ px: 3 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => navigate("/plan")}
          >
            Purchase Plan
          </Button>
        </Box>
      ) : (
        <>
          {!subscriptionBar && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor="#e6edf9"
              pl={2}
            >
              <Typography sx={{ py: 1 }} variant="body1" color="#545454" fontWeight={600}>
                {`Your subscription has been activated. Next renewal will be on ${renewalDate}`}
              </Typography>

              <IconButton onClick={() => handleCloseSubscriptionBar()}>
                <CloseIcon sx={{ fontSize: 24 }} color="#545454" />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default SubscriptionReminder;
